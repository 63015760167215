import { apiRequest } from "setup/api/api"
import { CandidatesEndpoints } from "setup/api/endpoints/endpoints"
import { GetFormAction, FormAction } from "utils/forms/form.types"
import { serializeAllErrorMessages } from "utils/forms/forms"
import {
  InterviewProgressValues,
  InterviewProgressField
} from "./constants/interview-progress.definitions"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"

export type OnChangeInterviewProgress = FormAction<InterviewProgressValues>
type GetOnChangeInterviewProgress = GetFormAction<InterviewProgressValues>

export const getOnChangeInterviewProgress: GetOnChangeInterviewProgress =
  (candidateId, onSuccess) => async (values, helpers) => {
    const { setFieldError, setSubmitting } = helpers

    const newProgress = values.stage
      ? { stage: values.stage, statusId: null }
      : values
    const [error, response] = await apiRequest.patch({
      endpoint: CandidatesEndpoints.Root,
      endpointParams: candidateId,
      data: {
        ...newProgress
      },
      config: {
        headers: {
          ...skipErrorHeader
        }
      }
    })

    setSubmitting(false)
    response && onSuccess!(response.data)

    error &&
      setFieldError(
        InterviewProgressField.Stage,
        serializeAllErrorMessages(error)
      )

    return [error, response]
  }
