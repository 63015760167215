import { generateLabel } from "components/AzureLocation/helper"
import { InterviewProgressStage } from "components/ProjectPersonRecord/assignment.definitions"
import { filterCandidates, groupCandidates } from "./assignment-module.helpers"
import {
  AssignmentState,
  AssignmentActionTypes,
  AssignmentContextValues,
  CandidateFilter
} from "./assignment-module.types"
import { AssignmentNotesInfoDefaultValues } from "./const"
import { AssignmentNote } from "../components/assignment-notes/types"

export const initialAssignmentState: AssignmentState = {
  pageNumber: 1,
  pageSize: 25,
  totalItems: 0,
  filterTotalNumber: 0,
  totalPages: 0,
  isLoading: true,
  isCandidatesLoading: true,
  candidates: [],
  selectedCandidatedId: "",
  assignmentDetails: null!,
  groupedCandidates: null,
  appliedFilters: [],
  filteredCandidates: null,
  notes: [],
  candidatePhotos: {},
  activeStages: {},
  selectedStage: "all",
  allAvailableFilters: {
    status: [],
    dueDates: null,
    assignedTo: null,
    tags: null
  },
  assignmentTemplates: {
    connectionRequest: "",
    consentNotification: "",
    coreMessage: "",
    introductoryEmail: "",
    liNotification: "",
    linkedinMessage: ""
  },
  notesPagination: {
    firstItemOnPage: 1,
    totalItemCount: 1,
    pageSize: 1,
    hasNextPage: false,
    hasPreviousPage: false,
    pageNumber: 1,
    maxPages: 5,
    pageCount: 1,
    onPageChange: () => {}
  },
  companies: [],
  companiesCount: 0,
  companyPage: {
    hasNextPage: false,
    hasPreviousPage: false,
    maxPages: 25,
    pageCount: 0,
    pageNumber: 0,
    pageSize: 0,
    totalItemCount: 0
  },

  companiesCandidates: null,
  selectedTab: "candidates",
  selectedCompany: "",
  assignmentNotesInfo: AssignmentNotesInfoDefaultValues
}

export const assignmentReducer = (
  state: AssignmentState,
  action: { type: string; payload?: any }
) => {
  const { type, payload } = action
  switch (type) {
    case AssignmentActionTypes.SetIsLoading:
      return { ...state, isLoading: payload }
    case AssignmentActionTypes.SetAssignmentDetails:
      if (payload.geoLocation) {
        payload.location = generateLabel(payload.geoLocation)
      }
      return { ...state, assignmentDetails: payload }
    case AssignmentActionTypes.SetIsCandidatesLoading:
      return { ...state, isCandidatesLoading: payload }
    case AssignmentActionTypes.SetCandidates:
      return {
        ...state,
        candidates: payload.candidates,
        filteredCandidates: payload.filteredCandidates || [],
        groupedCandidates: payload.groupedCandidates
      }
    case AssignmentActionTypes.SetSelectedCandidatedId:
      return { ...state, selectedCandidatedId: payload }
    case AssignmentActionTypes.AddCandidate:
      if (!payload.candidate) return state

      const newCandidates = [...state.candidates, payload.candidate]

      return {
        ...state,
        candidates: newCandidates,
        groupedCandidates: groupCandidates(newCandidates)
      }
    case AssignmentActionTypes.UpdateCandidate:
      if (!payload.candidate) return state

      const index = state.candidates.findIndex((candidate) => {
        return (
          candidate.linkPerson?.localPerson.id === payload.candidate.personId ||
          candidate.linkPerson?.dataPoolPerson?.id ===
            payload.candidate.personId
        )
      })
      if (index === -1) return state

      state.candidates[index] = {
        ...state.candidates[index],
        ...payload.candidate
      }

      const updatedCandidates = [...state.candidates]

      return {
        ...state,
        candidates: updatedCandidates,
        groupedCandidates: groupCandidates(updatedCandidates)
      }
    case AssignmentActionTypes.ApplyFilters:
      const newFilters: CandidateFilter[] = payload.candidateFilters || []
      const filteredCandidates = filterCandidates(state.candidates, newFilters)

      return {
        ...state,
        appliedFilters: newFilters,
        filteredCandidates: newFilters.length > 0 ? filteredCandidates : [],
        groupedCandidates: groupCandidates(filteredCandidates)
      }
    case AssignmentActionTypes.SetNotes:
      const sortedData = payload.sort(
        (a: AssignmentNote, b: AssignmentNote) =>
          new Date(b.updatedDate ? b.updatedDate : b.createdDate).getTime() -
          new Date(a.updatedDate ? a.updatedDate : a.createdDate).getTime()
      )
      return {
        ...state,
        notes: sortedData || []
      }
    case AssignmentActionTypes.UpdatePhoto: {
      return {
        ...state,
        candidatePhotos: {
          ...state.candidatePhotos,
          [payload.candidateId]: payload.photo
        }
      }
    }
    case AssignmentActionTypes.SetPageNumber: {
      return { ...state, pageNumber: payload }
    }
    case AssignmentActionTypes.SetRecordsCount:
      return {
        ...state,
        totalItems: payload?.totalItems || 0,
        totalPages: payload?.totalPages || 0,
        filterTotalNumber: payload?.filterTotalNumber || 0
      }
    case AssignmentActionTypes.SetActiveStages:
      if (
        state.selectedStage !== InterviewProgressStage.Archive &&
        state.appliedFilters.length
      ) {
        payload[InterviewProgressStage.Archive] = 0
      }
      return { ...state, activeStages: payload }
    case AssignmentActionTypes.SetSelectedStage:
      return { ...state, selectedStage: payload }
    case AssignmentActionTypes.SetAllAvailable: {
      return { ...state, allAvailableFilters: payload }
    }
    case AssignmentActionTypes.SetAssignmentTemplates: {
      return { ...state, assignmentTemplates: payload }
    }
    case AssignmentActionTypes.SetCompanies:
      return {
        ...state,
        companies: payload.companies,
        companiesCount: payload.companiesCount
      }
    case AssignmentActionTypes.SetCompanyPage: {
      return { ...state, companyPage: { ...payload } }
    }
    case AssignmentActionTypes.SetCompaniesCandidates: {
      return {
        ...state,
        companiesCandidates: {
          ...state.companiesCandidates,
          [payload.companyId]: {
            candidates: payload.candidates,
            page: payload.page
          }
        }
      }
    }
    case AssignmentActionTypes.SetSelectedTab: {
      return { ...state, selectedTab: payload }
    }
    case AssignmentActionTypes.UpdateCompanyCandidate:
      if (!payload.candidate) return state

      const tempCompaniesCandidates = state.companiesCandidates!
      Object.values(tempCompaniesCandidates).forEach((company) => {
        company.candidates.forEach((candidate) => {
          if (candidate.personId === payload.candidate.personId) {
            Object.assign(candidate, payload.candidate)
          }
        })
      })

      return {
        ...state,
        companiesCandidates: tempCompaniesCandidates
      }
    case AssignmentActionTypes.UpdateCompanyCandidateNote:
      const tempCompaniesCandidatesNote = state.companiesCandidates!

      Object.values(tempCompaniesCandidatesNote).forEach((company) => {
        company.candidates.forEach((candidate) => {
          if (candidate.personId === payload.candidateId) {
            candidate.note = payload.note
          }
        })
      })

      return {
        ...state,
        companiesCandidates: tempCompaniesCandidatesNote
      }
    case AssignmentActionTypes.SetSelectedCompany: {
      return { ...state, selectedCompany: payload }
    }
    case AssignmentActionTypes.SetAssignmentNotesInfoType: {
      return { ...state, assignmentNotesInfo: payload }
    }
    case AssignmentActionTypes.UpdateAssignmentNotesInfo: {
      return {
        ...state,
        assignmentNotesInfo: { ...state.assignmentNotesInfo, ...payload }
      }
    }
    case AssignmentActionTypes.SetNotesPagination: {
      return {
        ...state,
        notesPagination: { ...state.notesPagination, ...payload }
      }
    }
    default:
      return state
  }
}

export const initialAssignmentContextValues: AssignmentContextValues = {
  ...initialAssignmentState,
  paginationProps: {
    pageNumber: 0,
    pageSize: 0,
    hasNextPage: false,
    hasPreviousPage: false,
    maxPages: 0,
    pageCount: 0,
    totalItemCount: 0
  },
  assignmentId: "",
  updateStageCount: async () => ({ allTabCount: 0, stages: {} }),
  setAssignmentDetails: () => {},
  setSelectedCandidatedId: () => {},
  getAssignmentData: () => {},
  patchAssignmentData: () => ({} as any),
  getAssignmentCandidates: () => {},
  updateCandidateStage: () => () => {},
  addCandidate: () => {},
  updateCandidate: () => {},
  updateFilters: () => {},
  loadPreviousCandidatesFilters: () => {},
  setNotes: () => {},
  onPageChange: () => {},
  setSelectedStage: () => {},
  setRecordsCount: () => {},
  setActiveStage: () => {},
  setAssignmentTemplates: () => {},
  setCompanies: () => {},
  setCompanyPage: () => {},
  setCompaniesCandidates: () => {},
  setSelectedTab: () => {},
  updateCompanyCandidate: () => {},
  updateCompanyCandidateNote: () => {},
  updateCompaniesStage: () => {},
  setSelectedCompany: () => {},
  setAssignmentNotesInfo: () => {},
  updateAssignmentNotesInfo: () => {},
  setNotesPagination: () => {}
}
