import { FormikHelpers } from "formik"
import { apiRequest } from "setup/api/api"
import {
  PersonsEndpoints,
  AttributesEndpoints
} from "setup/api/endpoints/endpoints"
import { NormalizedReturnTuple } from "setup/api/utils/request-definitions"
import { callWithErrorMessages } from "utils/forms/forms"
import { FormAction } from "utils/forms/form.types"
import { CreatePersonValues } from "views/persons/components/person-details/form/constants/definitions"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"

export type OnPersonDetailsSubmitType = FormAction<CreatePersonValues>

type ApiRequestType = (
  values: CreatePersonValues
) => Promise<NormalizedReturnTuple>

const onSubmit =
  (request: ApiRequestType) =>
  async (
    values: CreatePersonValues,
    helpers: FormikHelpers<CreatePersonValues>
  ) => {
    const { setFieldError, setSubmitting } = helpers
    setSubmitting(true)

    const [error, response] = await request(values)

    setSubmitting(false)
    callWithErrorMessages(setFieldError, error)
    return [error, response]
  }

export const onCreatePersonSubmit = onSubmit((values) =>
  apiRequest.post({
    endpoint: PersonsEndpoints.Root,
    data: values
  })
)

export const sendPersonPatchRequest = (
  personId: string,
  values: CreatePersonValues
) => {
  return apiRequest.patch({
    endpoint: PersonsEndpoints.Root,
    data: values,
    endpointParams: personId,
    config: {
      headers: {
        ...skipErrorHeader
      }
    }
  })
}

export const onEditPersonSubmit = (personId: string) =>
  onSubmit((values) => sendPersonPatchRequest(personId, values))

export const linkPersonToDatapool = (personId: string) =>
  apiRequest.get({
    endpoint: PersonsEndpoints.LinkPerson,
    endpointParams: personId
  })

export const callProfileEndpoint = (personID: string) =>
  apiRequest.get({
    endpoint: PersonsEndpoints.Root,
    endpointParams: personID,
    config: {
      headers: {
        ...skipErrorHeader
      }
    }
  })

export const getPersonKeywords = (id: string) =>
  apiRequest.get({
    endpoint: PersonsEndpoints.Keywords,
    endpointParams: { id: id },
    config: {
      headers: {
        ...skipErrorHeader
      }
    }
  })

export const getPersonAttributes = async (id: string) => {
  const [, response] = await apiRequest.get({
    endpoint: AttributesEndpoints.AttributesLinksPerson,
    endpointParams: id,
    config: {
      headers: {
        ...skipErrorHeader
      }
    }
  })

  return response?.data
}

type ApiLinkedInRequestType = (
  linkedInUrl: string
) => Promise<NormalizedReturnTuple>

const onSubmitLinkedInUrl =
  (request: ApiLinkedInRequestType) =>
  async (
    linkedInUrl: string,
    helpers: FormikHelpers<{
      linkedInUrl: {
        value: string
      }
    }>
  ) => {
    const { setSubmitting } = helpers
    setSubmitting(true)

    const [error, response] = await request(linkedInUrl)

    setSubmitting(false)

    return [error, response]
  }

export const onAddLinkedInUrl = (personId: string) =>
  onSubmitLinkedInUrl((linkedInUrl) =>
    apiRequest.patch({
      endpoint: PersonsEndpoints.Root,
      data: { linkedInProfileUrl: linkedInUrl },
      endpointParams: personId,
      config: {
        headers: {
          ...skipErrorHeader
        }
      }
    })
  )
