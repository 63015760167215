import React, { useReducer, useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { CampaignsEndpoints } from "setup/api/endpoints/endpoints"
import { apiRequest } from "setup/api/api"
import { RouterUrl } from "setup/router/routes"
import { parseMomentDate } from "utils/moment"
import {
  campaignReducer,
  initialCampaignState
} from "./campaign-module.reducer"
import {
  CampaignActionTypes,
  SetCampaignDetails,
  SetIsLoading,
  GetCampaignData,
  SetNotes,
  SetContacts,
  GetCampaignContacts,
  SetSelectedContactId,
  Pagination,
  ApplyFilters,
  UpdateFilters,
  SetAllAvailableFilters,
  SetCampaignAITemplates,
  SetNotesPagination
} from "./campaign-module.types"
import { skipErrorHeader } from "setup/api/utils/skip-error-header"
import { CampaignContext } from "./campaign-module.context"
import { LocalPersonModel } from "models/LocalPerson/LocalPerson"
import { Contact } from "../components/contacts/type"
import { useTeam } from "views/team/team/team-module.context"
import { ProjectRecordFilter } from "components/ProjectRecordsFilters/types"
import {
  getAppliedFiltersParams,
  getFiltersFromSessionStorage,
  saveFiltersToSessionStorage,
  toBeRefreshed
} from "components/ProjectRecordsFilters/helpers"
import { sessionStorageKeys } from "setup/storage/storage.definitions"
import { Tag } from "components/Tags/tags.types"
import {
  getCampaignListContact,
  getAllAvailableFilters
} from "./campaign-module.helpers"
import { FilterArrayType } from "components/ProjectRecordsFilters/types"
import { fetchPhotosAndSetUpdatedEntities } from "models/LocalPerson/localPerson.actions"
import { PaginationProps } from "components/functional/pagination/Pagination"

type CampaignModuleProps = {
  children: React.ReactNode
}

export type CampaigntModuleLocationParams = {
  campaignId?: string
}

export const CampaignModule = (props: CampaignModuleProps) => {
  const [isRefreshed, setIsRefreshed] = useState(false)
  const { children } = props
  const { campaignId = "" } = useParams<CampaigntModuleLocationParams>()
  const navigate = useNavigate()
  const { fetchTeamMembers } = useTeam()

  const [state, dispatch] = useReducer(campaignReducer, initialCampaignState)

  const setIsLoading: SetIsLoading = (data) => {
    dispatch({ type: CampaignActionTypes.SetIsLoading, payload: data })
  }

  const setCampaignDetails: SetCampaignDetails = (data) => {
    dispatch({
      type: CampaignActionTypes.SetCampaignDetails,
      payload: data
    })
  }

  const setCampaignPaginationContact = (data: Pagination) => {
    dispatch({
      type: CampaignActionTypes.SetCampaignPaginationContact,
      payload: data
    })
  }

  const setNotes: SetNotes = useCallback((data) => {
    dispatch({
      type: CampaignActionTypes.SetNotes,
      payload: data
    })
  }, [])

  const setContacts: SetContacts = (contacts) => {
    dispatch({
      type: CampaignActionTypes.SetContacts,
      payload: contacts
    })
  }

  const setIsContactsLoading: SetIsLoading = (data) => {
    dispatch({
      type: CampaignActionTypes.SetIsContactsLoading,
      payload: data
    })
  }

  // const updatePhoto: UpdatePhoto = useCallback((contactId, photo) => {
  //   dispatch({
  //     type: CampaignActionTypes.UpdatePhoto,
  //     payload: { contactId, photo }
  //   })
  // }, [])

  const setSelectedContactId: SetSelectedContactId = (data) => {
    dispatch({
      type: CampaignActionTypes.SetSelectedContactId,
      payload: data
    })
  }

  const setAllAvailableFilters: SetAllAvailableFilters = useCallback(
    (data: FilterArrayType) => {
      dispatch({
        type: CampaignActionTypes.SetAllAvailableFilters,
        payload: data
      })
    },
    [dispatch]
  )

  const applyFilters: ApplyFilters = useCallback(
    (contactFilters: any) => {
      dispatch({
        type: CampaignActionTypes.ApplyFilters,
        payload: { contactFilters }
      })
    },
    [dispatch]
  )

  const setCampaignAITemplates: SetCampaignAITemplates = useCallback(
    (data: any) => {
      dispatch({
        type: CampaignActionTypes.SetCampaignAITemplates,
        payload: data
      })
    },
    [dispatch]
  )

  const setNotesPagination: SetNotesPagination = useCallback(
    (pagination: Partial<PaginationProps>) => {
      dispatch({
        type: CampaignActionTypes.SetNotesPagination,
        payload: pagination
      })
    },
    [dispatch]
  )

  const redirectToCampaignsList = useCallback(
    () => navigate(RouterUrl.AssignmentList),
    [navigate]
  )

  const getCampaignData: GetCampaignData = useCallback(
    async (campaignId) => {
      setIsLoading(true)
      const [error, response] = await apiRequest.get({
        endpoint: CampaignsEndpoints.Root,
        endpointParams: campaignId,
        config: {
          headers: {
            ...skipErrorHeader
          }
        }
      })

      if (error) {
        redirectToCampaignsList()
      } else {
        const data = response?.data

        const startDate = parseMomentDate(data?.startDate)

        setCampaignDetails({ ...data, startDate })
        setIsLoading(false)
      }
    },
    [redirectToCampaignsList]
  )

  const { contacts, paginationContact, appliedFilters, campaignDetails } = state

  const paramsFirstPage = useCallback(
    (appliedFilters: any) => {
      const params = getAppliedFiltersParams(appliedFilters)
      const pageNumber = 1
      params.append("pageSize", paginationContact.pageSize.toString())
      params.delete("page")
      params.append("page", pageNumber.toString())
      params.append("expandValues", "person")
      setCampaignPaginationContact({ pageNumber })
      return params
    },
    [paginationContact.pageSize]
  )

  const normalizedPersonData = useCallback((contacts: Contact[]) => {
    return contacts.map((contact: any) => ({
      ...contact,
      normalizedPersonData: new LocalPersonModel(
        contact?.linkPerson
      ).getPersonValues()
    }))
  }, [])

  const getCampaignContacts: GetCampaignContacts = useCallback(
    async (campaignId, params = new URLSearchParams()) => {
      setIsContactsLoading(true)
      params.append("pageSize", paginationContact.pageSize.toString())
      if (!params.has("page")) {
        params.append("page", paginationContact.pageNumber.toString())
      }
      params.append("expandValues", "person")

      const { contacts, totalItemCount, totalFilteredCount } =
        await getCampaignListContact(campaignId, params)

      let tempContacts = contacts

      if (!contacts.length && paginationContact.page !== 1) {
        const pageNumber = 1
        params.delete("page")
        params.append("page", pageNumber.toString())
        setCampaignPaginationContact({ pageNumber })

        const { contacts, totalItemCount, totalFilteredCount } =
          await getCampaignListContact(campaignId, params)
        tempContacts = contacts
        setCampaignPaginationContact({ totalItemCount, totalFilteredCount })
      }

      setCampaignPaginationContact({ totalItemCount, totalFilteredCount })
      let normalizedContacts = normalizedPersonData(tempContacts)
      fetchPhotosAndSetUpdatedEntities(normalizedContacts, setContacts)
      setContacts(normalizedContacts)
      setIsContactsLoading(false)
    },
    [paginationContact, normalizedPersonData]
  )

  const updateFilters: UpdateFilters = useCallback(
    (recordFilters: ProjectRecordFilter[]) => {
      applyFilters(recordFilters)
      saveFiltersToSessionStorage(
        recordFilters,
        campaignId,
        sessionStorageKeys.previousContactsFilters
      )
    },
    [applyFilters, campaignId]
  )

  useEffect(() => {
    if (!isRefreshed) return

    let isCampaignContactLoading = true

    const fetchCampaignContact = async () => {
      setIsContactsLoading(true)

      const params = paramsFirstPage(appliedFilters)

      const { contacts, totalItemCount, totalFilteredCount } =
        await getCampaignListContact(campaignId, params)

      if (isCampaignContactLoading) {
        setCampaignPaginationContact({ totalItemCount, totalFilteredCount })
        const tempContacts = normalizedPersonData(contacts)
        fetchPhotosAndSetUpdatedEntities(tempContacts, setContacts)
        setContacts(tempContacts)
        setIsContactsLoading(false)
      }
    }
    fetchCampaignContact()
    return () => {
      isCampaignContactLoading = false
    }
  }, [
    appliedFilters,
    campaignId,
    paramsFirstPage,
    normalizedPersonData,
    isRefreshed
  ])

  useEffect(() => {
    const savedFilters = getFiltersFromSessionStorage(
      sessionStorageKeys.previousContactsFilters
    )?.[campaignId]
    const tempSavedFilters =
      savedFilters?.filter((item) => item.recordProperty === "tags") || []
    const tempTags = campaignDetails?.tags

    let toBeRefreshed = false
    for (let filterTemp of tempSavedFilters) {
      const foundFilter = tempTags?.find(
        (item: any) => item.id === filterTemp.value.tags
      )
      if (foundFilter && foundFilter.name !== filterTemp.label) {
        toBeRefreshed = true
        break
      }
    }

    const toBeTagsUpdated = savedFilters?.find(
      (item) => item.recordProperty === "tags"
    )
    if (toBeTagsUpdated && toBeRefreshed) {
      campaignDetails?.tags?.forEach((tag: Tag) => {
        savedFilters.forEach((filter) => {
          if (tag.id === filter.value.tags) {
            filter.label = tag.name
            filter.filterKey = `tags${tag.name}`
          }
        })
      })
      updateFilters(savedFilters)
    }
  }, [updateFilters, campaignDetails, campaignId])

  useEffect(() => {
    if (campaignId) {
      getCampaignData(campaignId)
    }
  }, [getCampaignData, campaignId])

  useEffect(() => {
    if (!state.isLoading) {
      const sessionStorageFilters =
        getFiltersFromSessionStorage(
          sessionStorageKeys.previousContactsFilters
        )?.[campaignId] || []
      applyFilters(sessionStorageFilters)
      setIsRefreshed(true)
    }
  }, [state.isLoading, campaignId, applyFilters])

  const fetchAllAvailableFilters = useCallback(async () => {
    const { status, assignedTo, tags, dueDates } = await getAllAvailableFilters(
      campaignId
    )
    setAllAvailableFilters({ status, assignedTo, tags, dueDates })
  }, [campaignId, setAllAvailableFilters])

  const updateContactStage = useCallback(
    (contactId: string, newContact: Contact, value: {}) => {
      const newContacts = contacts
        .map((contact: Contact) =>
          contact.id === contactId ? { ...contact, ...newContact } : contact
        )
        .filter((contact: Contact) => contact.campaignId === campaignId)

      if (appliedFilters.length && toBeRefreshed(value, appliedFilters)) {
        const params = getAppliedFiltersParams(appliedFilters)
        getCampaignContacts(campaignId, params)
      } else {
        setContacts(newContacts)
      }
      fetchAllAvailableFilters()
    },
    [
      contacts,
      campaignId,
      appliedFilters,
      getCampaignContacts,
      fetchAllAvailableFilters
    ]
  )

  useEffect(() => {
    fetchTeamMembers()
  }, [fetchTeamMembers])

  useEffect(() => {
    fetchAllAvailableFilters()
  }, [fetchAllAvailableFilters])

  return (
    <CampaignContext.Provider
      value={{
        ...state,
        campaignId: campaignId,
        setCampaignDetails: setCampaignDetails,
        setCampaignPaginationContact,
        getCampaignData: getCampaignData,
        setNotes: setNotes,
        setContacts: setContacts,
        getCampaignContacts: getCampaignContacts,
        updateContactStage,
        setSelectedContactId: setSelectedContactId,
        updateFilters,
        setIsContactsLoading,
        setCampaignAITemplates,
        setNotesPagination
      }}
    >
      {children}
    </CampaignContext.Provider>
  )
}
