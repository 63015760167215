import React, { useState } from "react"
import { apiRequest } from "setup/api/api"
import { CandidatesEndpoints } from "setup/api/endpoints/endpoints"
import { Candidate } from "views/candidates/candidates.types"
import { CandidateContext, OnChangeInterviewProgress } from "./CandidateContext"
import { Nullable } from "tsdef"

type CandidateRecordWrapperProps = {
  candidate: Candidate
  personPhoto: Nullable<string>
  children: React.ReactNode
}

const CandidateRecordWrapper = ({
  candidate,
  children
}: CandidateRecordWrapperProps) => {
  const [isUpdating, setIsUpdating] = useState(false)

  const onChangeInterviewProgress: OnChangeInterviewProgress =
    (candidateId, onSuccess) => async (value, helpers) => {
      setIsUpdating(true)

      const { setSubmitting } = helpers

      const newProgress = value.stage
        ? { stage: value.stage, statusId: null }
        : value

      const [error, response] = await apiRequest.patch({
        endpoint: CandidatesEndpoints.Root,
        endpointParams: candidateId,
        data: { ...newProgress }
      })

      setSubmitting(false)
      setIsUpdating(false)
      response && onSuccess!(response.data)

      return [error, response]
    }

  const contextValues = {
    isUpdating,
    candidate,
    photoUrl: candidate.photo || null,
    onChangeInterviewProgress
  }

  return (
    <CandidateContext.Provider value={contextValues}>
      {children}
    </CandidateContext.Provider>
  )
}

export default CandidateRecordWrapper
