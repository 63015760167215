export enum NoteField {
  NoteTitle = "noteTitle",
  NoteDescription = "noteDescription"
}

export const initialDefaultNoteState = {
  [NoteField.NoteTitle]: "",
  [NoteField.NoteDescription]: ""
}

export const NotesInitialTouched = {
  [NoteField.NoteTitle]: true,
  [NoteField.NoteDescription]: true
}

export type NoteForm = {
  [NoteField.NoteTitle]: string
  [NoteField.NoteDescription]: string
}

export enum CommentaryField {
  CommentaryTitle = "commentaryTitle",
  CommentaryDescription = "commentaryDescription",
  CommentaryShowToClient = "commentaryShowToClient"
}

export const initialDefaultCommentaryState = {
  [CommentaryField.CommentaryTitle]: "",
  [CommentaryField.CommentaryDescription]: "",
  [CommentaryField.CommentaryShowToClient]: false
}

export enum BiographyField {
  BiographyDescription = "biographyDescription",
  SetAsMainBiography = "setAsMainBiography"
}

export const initialDefaultBiographyState = {
  [BiographyField.BiographyDescription]: "",
  [BiographyField.SetAsMainBiography]: false
}

export type BiographyForm = {
  [BiographyField.BiographyDescription]: string
  [BiographyField.SetAsMainBiography]: boolean
}

export const CommentaryInitialTouched = {
  [CommentaryField.CommentaryTitle]: true,
  [CommentaryField.CommentaryDescription]: true,
  [CommentaryField.CommentaryShowToClient]: true
}

export type CommentaryForm = {
  [CommentaryField.CommentaryTitle]: string
  [CommentaryField.CommentaryDescription]: string
  [CommentaryField.CommentaryShowToClient]: boolean
}

export enum LinkNoteToggleStatus {
  None = "none",
  Assignment = "assignment",
  Campaign = "campaign",
  Person = "person",
  Company = "company"
}

export enum LinkFormFields {
  Assignment = "assignment",
  Campaigns = "campaign",
  Person = "person",
  Company = "company"
}
