import React from "react"
import { NoteField } from "./definitions"
import { messages } from "setup/messages/messages"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { StyledFormikTextarea } from "../style"
import { Flex, theme } from "@ikiru/talentis-fpc"

const NoteFormLayout = () => (
  <Flex flexDirection="column" style={{ gap: theme.space.xs }}>
    <FormikInput
      id={NoteField.NoteTitle}
      name={NoteField.NoteTitle}
      label={messages.person.note.form.noteTitle}
    />
    <StyledFormikTextarea
      rows={2}
      id={NoteField.NoteDescription}
      name={NoteField.NoteDescription}
      label={messages.person.note.form.noteDescription}
      isMicrophone
    />
  </Flex>
)

export default React.memo(NoteFormLayout)
