import React, { useMemo, useCallback, useEffect, useState } from "react"
import { messages } from "setup/messages/messages"
import { Flex, Button, H5, ShareIcon, SectionHeader } from "@ikiru/talentis-fpc"
import { useClientPortalConfiguration } from "../../module/context"
import { ModalNames } from "setup/modal/modal.definitions"
import { ClientPortalShareSettingsModal } from "components/modals/ClientPortalShareSettingsModal"
import { useModal } from "utils/hooks/use-modal"
import { Share } from "views/assignments/assignment.types"
import {
  OnClientPortalShareCreateSubmit,
  onCreateClientPortalShareSubmit,
  onDeleteClientPortalShares,
  onGetClientPortalShares
} from "components/modals/ClientPortalShareSettingsModal/client-portal-share-form.actions"

const TopBar = () => {
  const { assignment } = useClientPortalConfiguration()
  const { open, close, openModals } = useModal()
  const [shares, setShares] = useState([] as Share[])

  const handleCallbackWithCloseModal = useCallback(() => {
    close(ModalNames.ClientPortalShareSettings)
  }, [close])

  const onSubmit: OnClientPortalShareCreateSubmit = useCallback(
    async (values, helpers) => {
      if (!assignment) return

      const [, response] = await onCreateClientPortalShareSubmit(
        assignment?.id
      )(values, helpers)

      if (response) {
        const share = response.data as Share
        setShares([...shares, share])
      }
      helpers.resetForm()
    },
    [assignment, shares]
  )

  const onDelete = useCallback(
    async (email: string) => {
      if (!assignment) return

      const response = await onDeleteClientPortalShares(assignment?.id, email)
      if (Array.isArray(response) && !response.length) {
        setShares(shares.filter((share: Share) => share.email !== email))
      }
    },
    [assignment, shares, setShares]
  )

  const receiveShares = useCallback(async () => {
    if (assignment?.id) {
      const shares = await onGetClientPortalShares(assignment?.id)
      setShares(shares)
    }
  }, [assignment])

  useEffect(() => {
    receiveShares()
  }, [receiveShares])

  const openModal = useCallback(() => {
    open(
      ModalNames.ClientPortalShareSettings,
      <ClientPortalShareSettingsModal
        accesses={shares}
        onClose={handleCallbackWithCloseModal}
        onDelete={onDelete}
        onSubmit={onSubmit}
        formActionsComponent={(formik) => {
          return (
            <Button
              mode="standard-green"
              type="submit"
              disabled={formik.isSubmitting || !formik.isValid}
              mt="s"
              mb="xxs"
            >
              <H5 m="none" color="white.standard">
                {messages.clientPortal.access.createAccessDetails}
              </H5>
            </Button>
          )
        }}
      />
    )
  }, [open, handleCallbackWithCloseModal, onSubmit, shares, onDelete])

  const isOpen = useMemo(
    () => !!openModals[ModalNames.ClientPortalShareSettings],
    [openModals]
  )

  useEffect(() => {
    if (isOpen) {
      openModal()
    }
  }, [openModal, isOpen])

  return (
    <Flex bg="grey.light" alignItems="center" justifyContent="space-between">
      <SectionHeader title={messages.clientPortal.label} size="xxSmall" />
      <Button
        prefix={<ShareIcon />}
        size="small"
        mode="secondary"
        onClick={openModal}
        mr="xs"
        width="90px"
      >
        {messages.clientPortal.share}
      </Button>
    </Flex>
  )
}

export default React.memo(TopBar)
