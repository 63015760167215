import React, { useEffect } from "react"
import { BodyText, colors, Flex } from "@ikiru/talentis-fpc"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import AttributesListTree from "./AttributesListTree"
import { RouterLink } from "components/RouterLink/RouterLink"
import { RouterUrl } from "setup/router/routes"
import { messages } from "setup/messages/messages"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import ReassignAttributes from "../assign-attributes/ReassignAttributes"

const AttributesList = () => {
  const { attributes, updateAttributes, changedNodes } = usePerson()

  const { openInPortal } = useModal()

  useEffect(() => {
    if (changedNodes?.length > 0) {
      openInPortal(ModalNames.ReassignAttributes, <ReassignAttributes />)
    }
  }, [changedNodes])

  return (
    <Flex flexDirection="column" width="100%">
      {attributes.length === 0 && (
        <Flex justifyContent="center" alignItems="center">
          <BodyText color={colors.grey.standard}>
            {messages.attributes.title}
            <RouterLink
              mb="0"
              to={RouterUrl.Attributes}
              href={RouterUrl.Attributes}
            >
              {messages.attributes.here}
            </RouterLink>
          </BodyText>
        </Flex>
      )}
      <AttributesListTree />
    </Flex>
  )
}

export default AttributesList
