import styled from "styled-components"
import { NoteLinks, fontFamilies } from "@ikiru/talentis-fpc"

export const StyledNoteLinks = styled(NoteLinks)`
  margin-left: 20px;
  h4 {
    font-size: 16px;
    line-height: 21px;
    font-family: ${fontFamilies.gibson};
    font-weight: 500;
  }
  p {
    width: 100%;
  }
  svg {
    width: 12px;
    height: 12px;
  }
`
