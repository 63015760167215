import moment, { Moment } from "moment"
import { CandidateFilter } from "views/assignments/assignment-module/assignment-module.types"

export const getAppliedFiltersParams = (
  appliedFilter: CandidateFilter[] = []
) => {
  let params = new URLSearchParams()

  appliedFilter?.forEach((filter) => {
    if (filter.candidateProperty === "interviewProgressState.stage") {
      const value = filter.value.eq as string
      params.append("stages", value)
    }
  })

  appliedFilter?.forEach((filter) => {
    if (filter.candidateProperty === "interviewProgressState.status") {
      const value = filter.value.eq as string
      params.append("StatusIds", value)

      filter.duplicatedValues?.forEach((value) => {
        params.append("StatusIds", value || "")
      })
    }
  })

  appliedFilter?.forEach((filter) => {
    if (filter.candidateProperty === "assignTo") {
      const value = filter.value.eq as string
      filter.filterKey !== "assignToNull"
        ? params.append("assignsTo", value)
        : params.append("assignToExists", "false")
    }
  })

  const fullDateArray: Moment[] = []
  appliedFilter?.forEach((filter) => {
    if (filter.candidateProperty === "dueDate") {
      const filterKey = filter.filterKey
      if (filterKey === "dueDateAnyDate" || filterKey === "dueDateNoDueDate") {
        const exists = filter.value.exists as Boolean
        params.append("dueDateExists", exists.toString())
      } else if (filterKey === "dueDateOverdue") {
        const lowestPossibleDate = moment("01/01/1900")
          .subtract(1, "d")
          .format("YYYY-MM-DD HH:mm:ss")
        params.append("DueDateFrom", lowestPossibleDate.toString())
      } else {
        const greastest = filter.value.gte as Date
        const lowest = filter.value.lte as Date
        fullDateArray.push(moment(greastest))
        fullDateArray.push(moment(lowest))
      }
    }
  })
  if (fullDateArray.length > 0) {
    const lowestDate = moment.min(fullDateArray).subtract(1, "d")
    const highestDate = moment.max(fullDateArray).subtract(1, "d")
    const lowestFormat = moment(lowestDate).format("YYYY-MM-DD HH:mm:ss")
    const highestFormat = moment(highestDate).format("YYYY-MM-DD HH:mm:ss")
    if (!params.has("DueDateFrom")) {
      params.append("DueDateFrom", lowestFormat.toString())
    }
    params.append("DueDateTo", highestFormat.toString())
  }

  appliedFilter?.forEach((filter) => {
    if (filter.candidateProperty === "tags") {
      const value = filter.value.tags as string
      filter.filterKey !== "noTags"
        ? params.append("tagIds", value)
        : params.append("tagIdExists", "false")
    }
  })

  return params
}
