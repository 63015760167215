import { useState } from "react"
import { useTaskManagement } from "views/homepage/task-management-module/task-management-module.context"
import {
  InterviewProgressStage,
  InterviewProgressStatus
} from "../candidate-record/components/InterviewProgress/definitions"
import { messages } from "setup/messages/messages"

type AssignmentStatus = { id: string; name: string }[]

export const useTaskFilterData = () => {
  const { allAvailableFilters } = useTaskManagement()

  const [statusFilter] = useState<AssignmentStatus>(
    (allAvailableFilters.status as AssignmentStatus) || {
      id: "",
      name: messages.assignment.filters.noSelectedStatus
    }
  )

  const [statusCampaignFilters] = useState<InterviewProgressStatus[]>(
    allAvailableFilters.status as unknown as InterviewProgressStatus[]
  )

  const [assignToFilter] = useState<string[]>(
    allAvailableFilters.assignedTo || []
  )
  const [stageFilter] = useState<InterviewProgressStage[]>(
    allAvailableFilters.stage || []
  )

  return {
    statusFilter,
    statusCampaignFilters,
    assignToFilter,
    stageFilter
  }
}
