import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useAttributes } from "./attributes-module/attributes-module.context"
import { AttributeTitle } from "./components/AttributeTitle"
import {
  Button,
  colors,
  Div,
  DropdownArrowIcon,
  Flex,
  H1,
  PlusIcon,
  PencilIcon,
  BodyText,
  HideIcon,
  ShowIcon,
  SmallText,
  H5,
  RemoveIcon
} from "@ikiru/talentis-fpc"
import {
  GlobalStyles,
  SwitcherIconStyled
} from "views/persons/components/person-keywords/styles"
import Tree from "rc-tree"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import AttributeTitleModal from "./form/AttributeTitleModal"
import { saveAttributes } from "./attributes-module/actions"
import { Attribute } from "./attributes-module/attributes-module.types"
import { updateWhereUsedInTree } from "./utils"
import { messages } from "setup/messages/messages"
import isEqual from "lodash/isEqual"
import { StyledAttributeTitle } from "./styles"
import AttributeInstructions from "./components/AttributeInstructions"

export const Attributes = () => {
  const { attributes, setAttributes, whereUsedList } = useAttributes()
  const [selectedKey, setSelectedKey] = useState("")
  const [newAttributes, setNewAttributes] = useState<Attribute[]>(attributes)
  const [treeData, setTreeData] = useState<Attribute[]>([])
  const [hoveredKey, setHoveredKey] = useState<string | null>(null)

  const isButtonDisabled = useMemo(
    () => isEqual(attributes, newAttributes),
    [attributes, newAttributes]
  )

  const firstNodes = useMemo(() => {
    return (newAttributes || []).map((node: Attribute) => ({
      ...node
    }))
  }, [newAttributes])

  const treeTitle = useMemo(
    () => firstNodes.find((node) => node.key === selectedKey)?.title || "",
    [selectedKey, firstNodes]
  )

  const { open, close } = useModal()

  const saveNewCategory = useCallback(
    (title: string, showIn: string[]) => {
      close(ModalNames.AttributeTitle)

      if (!title.trim()) return

      const newKey = `0-${Date.now()}`

      const newNode = {
        title: title,
        key: newKey,
        id: null,
        isHidden: false,
        whereUsed: [...showIn],
        children: []
      }

      setNewAttributes((prevTree) => [...prevTree, newNode])
    },
    [close, firstNodes.length, setNewAttributes]
  )

  const editCategory = useCallback(
    (newTitle: string, key: string, showIn: string[]) => {
      if (!newTitle.trim()) return

      setNewAttributes((prevTree) =>
        prevTree.map((node) =>
          node.key === key
            ? { ...node, title: newTitle, whereUsed: [...showIn], isEdit: true }
            : node
        )
      )
    },
    [setNewAttributes]
  )

  const addNewCategory = () => {
    open(
      ModalNames.AttributeTitle,
      <AttributeTitleModal
        onSave={saveNewCategory}
        whereUsedList={whereUsedList}
        isCategory
        header="Add attribute category"
      />
    )
  }

  const handleHideCategory = useCallback(
    (key: string) => {
      setNewAttributes((prevTree) =>
        prevTree.map((node) =>
          node.key === key
            ? {
                ...node,
                isHidden: !node.isHidden
              }
            : node
        )
      )
    },
    [setNewAttributes]
  )

  const handleDeleteCategory = useCallback(
    (keyToDelete: string) => {
      setNewAttributes((prevTree) =>
        prevTree.filter((node) => node.key !== keyToDelete)
      )
    },
    [setNewAttributes]
  )

  const saveNewAttribute = useCallback(
    (title: string) => {
      close(ModalNames.AttributeTitle)

      if (!title.trim()) return

      const newKey = `${selectedKey}-${Date.now()}`

      const newNode = {
        title: title,
        key: newKey,
        id: null,
        isHidden: false,
        children: [],
        whereUsed: []
      }

      setTreeData((prevTree) => [...prevTree, newNode])
    },
    [close, firstNodes.length, setNewAttributes, treeData, selectedKey]
  )

  useEffect(() => {
    if (selectedKey && treeData) {
      setNewAttributes((prevTree) =>
        prevTree.map((node) =>
          node.key === selectedKey ? { ...node, children: treeData } : node
        )
      )
    }
  }, [treeData, selectedKey])

  useEffect(() => {
    if (attributes.length > 0) {
      setNewAttributes(attributes)
    }
  }, [attributes])

  const addNewAttribute = () => {
    open(
      ModalNames.AttributeTitle,
      <AttributeTitleModal onSave={saveNewAttribute} />
    )
  }

  const handleAddNode = (title: string, key: string) => {
    close(ModalNames.AttributeTitle)
    const addNodeRecursively = (nodes: Attribute[]): Attribute[] => {
      return nodes.map((node: Attribute) => {
        if (node.key === key) {
          const newNode = {
            key: `${key}-${Date.now()}`,
            title: title,
            id: null,
            isHidden: false,
            children: [],
            whereUsed: []
          }
          return {
            ...node,
            children: [...(node.children || []), newNode]
          }
        }
        return {
          ...node,
          children: node.children ? addNodeRecursively(node.children) : []
        }
      })
    }

    setTreeData((prevData) => addNodeRecursively(prevData))
  }

  const handleTreeNode = (iconType: string, key: string, title?: string) => {
    const actions: Record<string, () => void> = {
      add: () =>
        open(
          ModalNames.AttributeTitle,
          <AttributeTitleModal onSave={(title) => handleAddNode(title, key)} />
        ),
      edit: () =>
        open(
          ModalNames.AttributeTitle,
          <AttributeTitleModal
            onSave={(title) => handleEditNode(title, key)}
            title={title}
          />
        ),
      hide: () => handleHideNode(key),
      delete: () => handleDeleteNode(key)
    }

    actions[iconType]?.()
  }

  const handleHideNode = (keyToHide: string) => {
    const toggleHiddenRecursively = (nodes: Attribute[]): Attribute[] => {
      return nodes.map((node) => ({
        ...node,
        isHidden: node.key === keyToHide ? !node.isHidden : node.isHidden,
        children: node.children ? toggleHiddenRecursively(node.children) : []
      }))
    }

    setTreeData((prevData) => toggleHiddenRecursively(prevData))
  }

  const handleEditNode = (title: string, key: string) => {
    const editNodeRecursively = (nodes: Attribute[]): Attribute[] => {
      return nodes.map((node) => {
        if (node.key === key) {
          return { ...node, title, isEdit: true }
        }
        return {
          ...node,
          children: node.children ? editNodeRecursively(node.children) : []
        }
      })
    }
    close(ModalNames.AttributeTitle)
    setTreeData((prevData) => editNodeRecursively(prevData))
  }

  const handleDeleteNode = useCallback(
    (keyToDelete: string) => {
      const deleteNodeRecursively = (nodes: Attribute[]): Attribute[] => {
        return nodes
          .filter((node) => node.key !== keyToDelete)
          .map((node) => ({
            ...node,
            children: node.children ? deleteNodeRecursively(node.children) : []
          }))
      }

      setTreeData((prevData) => deleteNodeRecursively(prevData))
    },
    [setNewAttributes]
  )

  const renderTreeNodes = (data: Attribute[]): any =>
    data.map((node) => ({
      title: (
        <Flex
          onMouseEnter={() => setHoveredKey(node.key)}
          onMouseLeave={() => setHoveredKey(null)}
          alignItems="center"
        >
          <StyledAttributeTitle
            m="0"
            isNull={node.id === null || !!node.isEdit}
            isHidden={node.isHidden}
          >
            {node.title}
          </StyledAttributeTitle>
          {hoveredKey === node.key && (
            <Flex ml="30px">
              <Flex
                mx="5px"
                alignItems="center"
                onClick={() => handleTreeNode("edit", node.key, node.title)}
              >
                <PencilIcon color={colors.grey.light} width={12} height={12} />
                <SmallText
                  ml="3px"
                  mb="0"
                  fontFamily="canada-type-gibson"
                  color={colors.grey.standard}
                >
                  Edit
                </SmallText>
              </Flex>
              {node.id === null ||
                (!node.isUsed && (
                  <Flex
                    mx="5px"
                    alignItems="center"
                    onClick={() => handleTreeNode("delete", node.key)}
                  >
                    <RemoveIcon
                      color={colors.grey.light}
                      width={12}
                      height={12}
                    />
                    <SmallText
                      ml="3px"
                      mb="0"
                      fontFamily="canada-type-gibson"
                      color={colors.grey.standard}
                    >
                      Delete
                    </SmallText>
                  </Flex>
                ))}
              {node.isHidden ? (
                <Flex
                  mx="5px"
                  alignItems="center"
                  onClick={() => handleTreeNode("hide", node.key)}
                >
                  <HideIcon color={colors.grey.light} width={12} height={12} />
                  <SmallText
                    ml="3px"
                    mb="0"
                    fontFamily="canada-type-gibson"
                    color={colors.grey.standard}
                  >
                    Unhide
                  </SmallText>
                </Flex>
              ) : (
                <Flex
                  mx="5px"
                  alignItems="center"
                  onClick={() => handleTreeNode("hide", node.key)}
                >
                  <ShowIcon color={colors.grey.light} width={12} height={12} />
                  <SmallText
                    ml="3px"
                    mb="0"
                    fontFamily="canada-type-gibson"
                    color={colors.grey.standard}
                  >
                    Hide
                  </SmallText>
                </Flex>
              )}
              <Flex
                mx="5px"
                alignItems="center"
                onClick={() => handleTreeNode("add", node.key)}
              >
                <Button
                  mode="standard-white"
                  size="extra-small"
                  mr="s"
                  onClick={() => handleTreeNode("add", node.key)}
                  prefix={<PlusIcon width={12} height={12} />}
                >
                  Add child attribute
                </Button>
              </Flex>
            </Flex>
          )}
        </Flex>
      ),
      key: node.key,
      children: node.children ? renderTreeNodes(node.children) : []
    }))

  const openNode = useCallback(
    (nodeKey: string) => {
      setSelectedKey(nodeKey)
      const tempNodes = newAttributes?.find((node) => node.key === nodeKey)

      setTreeData(tempNodes?.children ? tempNodes.children : [])
    },
    [newAttributes, setSelectedKey, setTreeData]
  )

  const saveTree = useCallback(async () => {
    try {
      const updatedTree = updateWhereUsedInTree(newAttributes)
      const tempAttributes = await saveAttributes(updatedTree)
      setAttributes(tempAttributes)

      if (selectedKey) {
        const tempNodes = tempAttributes?.find(
          (node: Attribute) => node.key === selectedKey
        )
        setTreeData(tempNodes?.children ? tempNodes.children : [])
      }
    } catch (error) {
      console.error("Error saving tree:", error)
    }
  }, [saveAttributes, newAttributes, setAttributes, selectedKey])

  return (
    <>
      <H1 my="none" color="green.standard">
        {messages.attributes.attribute}
      </H1>

      <Flex justifyContent="space-between">
        <H5 m="0" mt="10px">
          {messages.attributes.title}
        </H5>

        <Button
          type="button"
          mode="primary"
          size="small"
          onClick={() => saveTree()}
          disabled={isButtonDisabled}
        >
          {messages.generic.save}
        </Button>
      </Flex>
      <Flex backgroundColor={colors.grey.lightest} mt="20px">
        <Flex flexDirection="column">
          <BodyText ml="xs" mb="0">
            {messages.attributes.selectCategory}
          </BodyText>
          <Flex flexDirection="column" mt="10px">
            {firstNodes.map((item) => (
              <AttributeTitle
                attribute={item}
                key={item.key}
                openNode={openNode}
                handleEditAttribute={editCategory}
                handleHide={handleHideCategory}
                handleDelete={handleDeleteCategory}
              />
            ))}

            <Flex my="m">
              <Button
                mode="standard-white"
                size="extra-small"
                ml="xs"
                onClick={addNewCategory}
                prefix={<PlusIcon width={12} height={12} />}
              >
                {messages.attributes.addNewCategory}
              </Button>
            </Flex>
          </Flex>
        </Flex>

        {selectedKey && (
          <Flex flexDirection="column" width="100%">
            <Flex
              mt="10px"
              mr="10px"
              ml="10px"
              backgroundColor={colors.green.dark}
            >
              <H5 m="0" py="5px" pl="10px" color={colors.white.standard}>
                {treeTitle}
              </H5>
            </Flex>

            <Div mt="10px" ml="15px">
              <GlobalStyles />
              <Tree
                treeData={renderTreeNodes(treeData)}
                switcherIcon={(nodeProps) => {
                  if (nodeProps.isLeaf) {
                    return null
                  }
                  return (
                    <SwitcherIconStyled>
                      <DropdownArrowIcon width={8} height={8} />
                    </SwitcherIconStyled>
                  )
                }}
                showIcon={false}
              />

              {treeData.length === 0 && (
                <BodyText ml="m">{messages.attributes.noAttributes}</BodyText>
              )}
              <Button
                mode="standard-white"
                size="extra-small"
                mr="s"
                mt="20px"
                mb="20px"
                onClick={addNewAttribute}
                prefix={<PlusIcon width={12} height={12} />}
              >
                {messages.attributes.addNewAttribute}
              </Button>
            </Div>
          </Flex>
        )}
      </Flex>
      <Flex mt="20px">
        <Button
          type="button"
          mode="primary"
          size="small"
          onClick={() => saveTree()}
          disabled={isButtonDisabled}
        >
          {messages.generic.save}
        </Button>
      </Flex>
      <AttributeInstructions />
    </>
  )
}
