import React from "react"
import { Formik, Form } from "formik"
import { addNoteValidationSchema } from "./schema"
import { NoteAddFormProps } from "./types"
import NoteFormLayout from "./NoteFormLayout"
import { initialDefaultNoteState } from "./definitions"
import { StyledDiv } from "../style"
import { Flex } from "@ikiru/talentis-fpc"

const NoteAddForm = ({ formActionsComponent, onSubmit }: NoteAddFormProps) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      width="100vw"
      height="100vh"
    >
      <StyledDiv>
        <Formik
          initialValues={initialDefaultNoteState}
          validationSchema={addNoteValidationSchema}
          onSubmit={onSubmit}
        >
          {(formik) => {
            return (
              <Form>
                {formActionsComponent?.(formik)}
                <NoteFormLayout />
              </Form>
            )
          }}
        </Formik>
      </StyledDiv>
    </Flex>
  )
}

export default React.memo(NoteAddForm)
