import styled from "styled-components"
import { Flex, H5, H6, colors } from "@ikiru/talentis-fpc"

export const StyledAttributesTitle = styled(Flex)`
  width: 350px;
  color: #6bc4c8;
  margin-left: 10px;
  margin-bottom: 5px;
  cursor: pointer;
`

export const CategoryTitle = styled(H5)<{ isNull: boolean; isHidden: boolean }>`
  margin: 0;
  color: ${({ isNull, isHidden }) =>
    isNull
      ? colors.orange.standard
      : isHidden
      ? colors.grey.dark
      : colors.default};
  opacity: ${({ isHidden }) => (isHidden ? 0.5 : 1)};
`

export const StyledAttributeTitle = styled(H6)<{
  isNull: boolean
  isHidden: boolean
}>`
  margin: 0;
  color: ${({ isNull, isHidden }) =>
    isNull
      ? colors.orange.standard
      : isHidden
      ? colors.grey.dark
      : colors.default};
  opacity: ${({ isHidden }) => (isHidden ? 0.5 : 1)};
`
