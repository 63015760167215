import { Attribute } from "./attributes-module/attributes-module.types"

export const updateWhereUsedInTree = (tree: Attribute[]) => {
  const updateNode = (node: Attribute, parentWhereUsed: string[] = []) => {
    node.whereUsed =
      parentWhereUsed.length > 0 ? parentWhereUsed : node.whereUsed

    if (node.children.length > 0) {
      node.children = node.children.map((child: Attribute) =>
        updateNode(child, node.whereUsed)
      )
    }

    return node
  }

  return tree.map((node: Attribute) => updateNode(node))
}
