import React, { useState } from "react"
import {
  Flex,
  PencilIcon,
  colors,
  RemoveIcon,
  ShowIcon,
  HideIcon,
  H6
} from "@ikiru/talentis-fpc"
import { CategoryTitle, StyledAttributesTitle } from "../styles"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import AttributeTitleModal from "../form/AttributeTitleModal"
import { Attribute } from "../attributes-module/attributes-module.types"
import { useAttributes } from "../attributes-module/attributes-module.context"

type AttributeTitleProps = {
  attribute: Attribute
  openNode: (nodeKey: string) => void
  handleEditAttribute: (title: string, key: string, showIn: string[]) => void
  handleHide: (key: string) => void
  handleDelete: (key: string) => void
}

export const AttributeTitle = ({
  attribute,
  openNode,
  handleEditAttribute,
  handleHide,
  handleDelete
}: AttributeTitleProps) => {
  const { whereUsedList } = useAttributes()
  const [isHover, setIsHover] = useState(false)

  const handleIconClick = (
    iconType: string,
    key: string,
    event: React.MouseEvent
  ) => {
    event.stopPropagation()

    const actions: Record<string, () => void> = {
      edit: () =>
        open(
          ModalNames.AttributeTitle,
          <AttributeTitleModal
            onSave={saveEditAttributes}
            title={attribute.title}
            whereUsed={attribute.whereUsed}
            header="Edit attribute category"
            whereUsedList={whereUsedList}
            isCategory
          />
        ),
      delete: () => handleDelete(key),
      hide: () => handleHide(key)
    }

    actions[iconType]?.()
  }

  const { open, close } = useModal()

  const saveEditAttributes = (title: string, showIn: string[]) => {
    close(ModalNames.AttributeTitle)
    handleEditAttribute(title, attribute.key, showIn)
  }

  return (
    <>
      <StyledAttributesTitle
        key={attribute.key}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={() => openNode(attribute.key)}
      >
        <CategoryTitle
          isNull={attribute.id === null || !!attribute.isEdit}
          isHidden={attribute.isHidden}
        >
          {attribute.title}
        </CategoryTitle>
        {isHover && (
          <Flex ml="30px">
            <Flex
              mx="5px"
              alignItems="center"
              onClick={(event) => handleIconClick("edit", attribute.key, event)}
            >
              <PencilIcon color={colors.grey.light} width={12} height={12} />
              <H6 ml="3px" my="0" color={colors.grey.standard}>
                Edit
              </H6>
            </Flex>
            {attribute.id === null ||
              (!attribute.isUsed && (
                <Flex
                  mx="5px"
                  alignItems="center"
                  onClick={(event) =>
                    handleIconClick("delete", attribute.key, event)
                  }
                >
                  <RemoveIcon
                    color={colors.grey.light}
                    width={12}
                    height={12}
                  />
                  <H6 ml="3px" my="0" color={colors.grey.standard}>
                    Delete
                  </H6>
                </Flex>
              ))}
            {attribute.isHidden ? (
              <Flex
                mx="5px"
                alignItems="center"
                onClick={(event) =>
                  handleIconClick("hide", attribute.key, event)
                }
              >
                <HideIcon color={colors.grey.light} width={12} height={12} />
                <H6 ml="3px" my="0" color={colors.grey.standard}>
                  Unhide
                </H6>
              </Flex>
            ) : (
              <Flex
                mx="5px"
                alignItems="center"
                onClick={(event) =>
                  handleIconClick("hide", attribute.key, event)
                }
              >
                <ShowIcon color={colors.grey.light} width={12} height={12} />
                <H6 ml="3px" my="0" color={colors.grey.standard}>
                  Hide
                </H6>
              </Flex>
            )}
          </Flex>
        )}
      </StyledAttributesTitle>
    </>
  )
}
