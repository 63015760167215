import React, { useCallback, useEffect, useMemo, useState } from "react"
import Tree from "rc-tree"
import "rc-tree/assets/index.css"
import {
  Button,
  Div,
  Flex,
  H6,
  Link,
  ModalSectionHeader
} from "@ikiru/talentis-fpc"
import { DropdownArrowIcon } from "@ikiru/talentis-fpc"
import {
  SwitcherIconStyled,
  AssignAttributesModalWrapper,
  GlobalStyles,
  StyledBodyWrapper,
  StyledAttributesTitle
} from "../../styles"
import { messages } from "setup/messages/messages"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { assignAttributes } from "../../actions"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import AssignAttributesAccordion from "./AssignAttributesAccordion"
import {
  getAllKeys,
  getCheckedKeys,
  getCheckedLeafNodeIds,
  isLeafNode,
  setCheckedFlag
} from "../../helper"

type AssignAttributesProps = {
  keyNode?: string
}

const AssignAttributes = ({ keyNode }: AssignAttributesProps) => {
  const [valuesChecked, setValuesChecked] = useState<any>()
  const [checkedTreeKeys, setCheckedTreeKeys] = useState([])
  const [selectedKey, setSelectedKey] = useState(keyNode)
  const { attributes, updateAttributes, personId, hiddenAttributesIds } =
    usePerson()

  const firstNodes = useMemo(() => {
    return valuesChecked?.map((node: any) => ({
      title: node.title,
      key: node.key
    }))
  }, [valuesChecked])

  const treeNodes = useMemo(() => {
    if (!valuesChecked || !selectedKey) return []
    const tempNodes = valuesChecked?.find((node) => node.key === selectedKey)
    return tempNodes?.children ? tempNodes?.children : [tempNodes]
  }, [selectedKey, valuesChecked])

  const nodeTitle = useMemo(() => {
    return firstNodes?.find((node) => node.key === selectedKey)?.title
  }, [selectedKey, firstNodes])

  const { close } = useModal()

  const closeAssignAttributes = () => {
    close(ModalNames.AssignAttributes)
  }

  const saveChanges = useCallback(async () => {
    const checkedLeafNodeIds = getCheckedLeafNodeIds(attributes)

    const updatedAttributes = await assignAttributes(personId, [
      ...checkedLeafNodeIds,
      ...hiddenAttributesIds
    ])

    updateAttributes(updatedAttributes)
    close(ModalNames.AssignAttributes)
  }, [attributes, personId, updateAttributes])

  useEffect(() => {
    if (attributes?.length > 0) {
      setValuesChecked(attributes)
      const checkedKeysTemp = getCheckedKeys(attributes) || []
      setCheckedTreeKeys(checkedKeysTemp)
    }
  }, [attributes])

  const onCheck = useCallback(
    (checkedKeys, info) => {
      const allKeys = getAllKeys(treeNodes)

      let updatedArray: string[] = []

      if (info.checked) {
        updatedArray = [...checkedTreeKeys, info.node.key, ...checkedKeys]
      } else {
        let tempArray = allKeys.filter((item) => !checkedKeys.includes(item))
        updatedArray = checkedTreeKeys.filter(
          (item) => !tempArray.includes(item)
        )
      }

      const leafKeys = updatedArray.filter((key) =>
        isLeafNode(key, valuesChecked)
      )

      setCheckedTreeKeys(leafKeys)

      setValuesChecked(setCheckedFlag(valuesChecked, updatedArray))
    },
    [checkedTreeKeys, valuesChecked, treeNodes, valuesChecked]
  )

  const removeAttributes = useCallback(
    (key: string) => {
      const tempCheckedKeys = checkedTreeKeys.filter(
        (checkedTreeKey) => checkedTreeKey !== key
      )

      setCheckedTreeKeys(tempCheckedKeys)
      setValuesChecked((prevValuesChecked) =>
        setCheckedFlag(prevValuesChecked, tempCheckedKeys)
      )
    },
    [checkedTreeKeys, setCheckedTreeKeys, setValuesChecked]
  )

  const openNode = (nodeKey?: string) => {
    setSelectedKey(nodeKey || "")
  }

  return (
    <AssignAttributesModalWrapper>
      <ModalSectionHeader
        title="Assign  Attributes"
        size="xSmall"
        actions={
          <>
            <Button size="small" mr="xxs" onClick={() => saveChanges()}>
              {messages.form.generic.save}
            </Button>
            <Button
              mode="standard-white"
              size="small"
              type="button"
              onClick={closeAssignAttributes}
            >
              {messages.generic.cancel}
            </Button>
          </>
        }
      />
      <Div pt="15px" pl="10px">
        <Link
          href="#"
          standalone
          mb="0"
          mr="10px"
          onClick={() => setSelectedKey("")}
        >
          All categories
        </Link>
        {selectedKey && (
          <>
            <span>{"•"}</span>
            <Link href="#" standalone mb="0" ml="10px">
              {nodeTitle}
            </Link>
          </>
        )}
      </Div>

      <StyledBodyWrapper>
        {selectedKey ? (
          <Div>
            <GlobalStyles />
            <Tree
              treeData={treeNodes}
              checkable
              checkedKeys={checkedTreeKeys}
              onCheck={onCheck}
              switcherIcon={(nodeProps) => {
                if (nodeProps.isLeaf) {
                  return null
                }
                return (
                  <SwitcherIconStyled>
                    <DropdownArrowIcon width={8} height={8} />
                  </SwitcherIconStyled>
                )
              }}
              showIcon={false}
            />
          </Div>
        ) : (
          <Flex flexDirection="column" width="200px" maxWidth="200px">
            {firstNodes?.map((item) => (
              <StyledAttributesTitle
                key={item.key}
                onClick={() => setSelectedKey(item.key)}
              >
                <H6 m="0">{item.title}</H6>
              </StyledAttributesTitle>
            ))}
          </Flex>
        )}

        <AssignAttributesAccordion
          attributes={valuesChecked}
          remove={removeAttributes}
          nodeTitle={nodeTitle}
          openNode={openNode}
        />
      </StyledBodyWrapper>
    </AssignAttributesModalWrapper>
  )
}

export default AssignAttributes
