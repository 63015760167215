import React from "react"
import { Button, ButtonProps, ModalSectionHeader } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { ModalNames } from "setup/modal/modal.definitions"
import { useModal } from "utils/hooks/use-modal"
import { FormikProps } from "formik"

type FormActionButtonsProps = {
  title: string
  ModalName?: ModalNames
  formik?: FormikProps<any>
  buttonName?: string
}
const FormActionButtons = ({
  title,
  ModalName = ModalNames.EditPersonDetails,
  formik,
  buttonName
}: FormActionButtonsProps) => {
  const { close } = useModal()

  const submitButtonProps: ButtonProps = {
    ...(!formik && { type: `submit` }),
    ...(formik && { onClick: () => formik.submitForm() })
  }

  return (
    <ModalSectionHeader
      title={title}
      size="xSmall"
      actions={
        <>
          <Button size="small" mr="xxs" {...submitButtonProps}>
            {buttonName ? buttonName : messages.form.generic.save}
          </Button>
          <Button
            mode="standard-white"
            size="small"
            type="button"
            onClick={() => close(ModalName)}
          >
            {messages.generic.cancel}
          </Button>
        </>
      }
    />
  )
}

export default FormActionButtons
