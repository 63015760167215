import { Div } from "@ikiru/talentis-fpc"
import { FormikTextarea } from "components/functional/formik/formik-textarea/FormikTextarea"
import { Form } from "formik"
import styled from "styled-components"

export const StyledNotesList = styled.ul`
  list-style-type: none;
  padding: 0;
  width: 100%;
  margin: 0;
`
export const StyledDiv = styled(Div)`
  width: clamp(300px, 70%, 800px);
  min-height: 400px;
  background-color: ${({ theme }) => theme.colors?.grey?.lightest};
  padding: ${({ theme }) => theme.space.m}px;
`
export const StyledFormikTextarea = styled(FormikTextarea)`
  min-height: 150px;
  @media (max-width: 768px) {
    min-height: 200px;
  }
`
export const StyledForm = styled(Form)``
