import { InterviewProgressStage } from "../components/candidates-list/components/candidate-record/components/InterviewProgress/definitions"

export const interviewStagesOrder: InterviewProgressStage[] = [
  InterviewProgressStage.Placed,
  InterviewProgressStage.Offer,
  InterviewProgressStage.ThirdClientInterview,
  InterviewProgressStage.SecondClientInterview,
  InterviewProgressStage.FirstClientInterview,
  InterviewProgressStage.Shortlist,
  InterviewProgressStage.InternalInterview,
  InterviewProgressStage.Screening,
  InterviewProgressStage.Source,
  InterviewProgressStage.Identified,
  InterviewProgressStage.Archive
]
