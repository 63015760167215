import React, { useEffect, useState } from "react"
import { apiRequest } from "setup/api/api"
import {
  AssignmentsEndpoints,
  CampaignsEndpoints
} from "setup/api/endpoints/endpoints"

type AssignmentRequestValues = {
  name: string
  id: string
}

const useGetFormData = () => {
  const [allAssignments, setAllAssignments] = useState<
    { value: string; label: string }[]
  >([])

  const [allCampaigns, setAllCampaigns] = useState<
    { value: string; label: string }[]
  >([])

  useEffect(() => {
    const setAvailableAssignments = async () => {
      const [, response] = await apiRequest.get<{
        hasAssignment: null
        simpleActiveAssignments: AssignmentRequestValues[]
      }>({
        endpoint: AssignmentsEndpoints.SimpleList,
        config: { params: { totalItemCount: 300 } }
      })

      const formattedAssignment =
        response?.data.simpleActiveAssignments.map((values) => ({
          value: values.id,
          label: values.name
        })) || []
      response && setAllAssignments(formattedAssignment)
    }

    const setAvailableCampaign = async () => {
      const [, response] = await apiRequest.get({
        endpoint: CampaignsEndpoints.SimpleList,
        config: { params: { totalItemCount: 300, status: "active" } }
      })

      const formattedCampaign: any[] =
        response?.data.simpleActiveCampaigns.map((values: any) => ({
          value: values.id,
          label: values.name
        })) || []
      response && setAllCampaigns(formattedCampaign)
    }
    setAvailableCampaign()
    setAvailableAssignments()
  }, [])

  return { allAssignments, allCampaigns }
}

export default useGetFormData
