import {
  Avatar,
  BodyText,
  Button,
  ConnectedIcon,
  Div,
  Flex,
  H6,
  Loader,
  SmallText,
  spacing
} from "@ikiru/talentis-fpc"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { PaginationProps } from "components/functional/pagination/Pagination"
import { AvatarWrapper } from "components/PersonRecords/OverlayOpenner/PersonAvatar/style"
import { Formik } from "formik"
import React, { useRef, useState } from "react"
import { Nullable } from "tsdef"
import { onSearchPersonSubmit } from "views/persons/pages/search-person/search-person/search-person.actions"
import { formatResults } from "views/persons/pages/search-person/utils"
import { PersonContainer, PersonListContainer } from "./style"

type PersonSearchProps = {
  onLink: (personId: string) => void
}

const PersonSearch = ({ onLink }: PersonSearchProps) => {
  const [selectedCandidate, setSelectedCandidate] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [foundPerson, setFoundPerson] = useState<
    | {
        id: string
        name: string
        company: string
        jobTitle: string
        location: string
        photo: string
      }[]
    | null
  >(null)

  const [pagination, setPagination] = useState<Nullable<PaginationProps>>({
    hasNextPage: false,
    hasPreviousPage: false,
    isFirstPage: false,
    isLastPage: false,
    lastItemOnPage: undefined,
    pageCount: 0,
    pageSize: 0,
    totalItemCount: 0,
    pageNumber: 1,
    maxPages: 0,
    onPageChange: () => {}
  })

  const formikBRef: any = useRef()

  const submitForm = async (values: any, formik: any) => {
    setIsLoading(true)
    setSelectedCandidate("")
    const response = await onSearchPersonSubmit(values, formik, pagination)
    const personsFound = formatResults(response.results) as any[]

    setFoundPerson(personsFound || [])

    setIsLoading(false)
  }

  const onLinkPerson = () => {
    onLink(selectedCandidate)
    setSelectedCandidate("")
    setFoundPerson(null)
  }

  return (
    <Div width="100%">
      <Flex alignItems="center" justifyContent="flex-start">
        <Formik
          initialValues={{ searchString: "" }}
          innerRef={formikBRef}
          onSubmit={submitForm}
        >
          {({ submitForm }) => (
            <Flex width="100%" alignItems="center">
              <Div width="100%">
                <FormikInput
                  label="Search for contact"
                  id="searchString"
                  variant="default"
                  name="searchString"
                />
              </Div>
              <Div ml="xs">
                <Button
                  onClick={submitForm}
                  type="submit"
                  width="170px"
                  size="small"
                  mode="standard-white"
                >
                  Search for contacts
                </Button>
              </Div>
              {isLoading && (
                <Div ml="xs">
                  <Loader size="small" />
                </Div>
              )}
            </Flex>
          )}
        </Formik>
        {selectedCandidate && (
          <Div ml="xxs">
            <Button
              onClick={onLinkPerson}
              mode="standard-green"
              width="150px"
              size="small"
            >
              Link person
            </Button>
          </Div>
        )}
      </Flex>
      {foundPerson &&
        (!!foundPerson?.length ? (
          <PersonListContainer>
            {foundPerson?.map((person) => {
              return (
                <PersonContainer
                  key={person.id}
                  isSelected={selectedCandidate === person.id}
                  onClick={() => setSelectedCandidate(person.id)}
                >
                  <Div>
                    <AvatarWrapper>
                      <Avatar
                        photo={person.photo}
                        width={30}
                        height={30}
                        name={person.name}
                        disableHoverOpacity
                      />
                    </AvatarWrapper>
                  </Div>
                  <Div ml="xs">
                    <Flex flexWrap="nowrap">
                      <H6 m="0" mr="xxs">
                        {person.name}
                      </H6>
                      <ConnectedIcon width={spacing.m} />
                    </Flex>
                    <Flex flexDirection="column">
                      <SmallText m="0">{person.company}</SmallText>
                      <SmallText m="0">{person.jobTitle}</SmallText>
                      <SmallText m="0">{person.location}</SmallText>
                    </Flex>
                  </Div>
                </PersonContainer>
              )
            })}
          </PersonListContainer>
        ) : (
          <PersonListContainer>
            <BodyText>No results found</BodyText>
          </PersonListContainer>
        ))}
    </Div>
  )
}

export default PersonSearch
