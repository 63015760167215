import React, { useCallback, useEffect, useState } from "react"
import { Form, Formik } from "formik"
import {
  AttributeFields,
  attributesInitialValues,
  attributesValidationSchema
} from "./definition"
import { FormikInput } from "components/functional/formik/formik-input/FormikInput"
import { messages } from "setup/messages/messages"
import { Div, H6 } from "@ikiru/talentis-fpc"
import { ModalNames } from "setup/modal/modal.definitions"
import { ModalFormContainer } from "views/persons/components/person-details-tab/components/person-details-section/form/style"
import FormActionButtons from "views/persons/components/person-details-tab/components/FormActionButtons"
import { ShowInBody } from "../components/ShowInBody"

type AttributesValues = typeof attributesInitialValues

type AttributeTitleModalProps = {
  onSave: (value: string, showIn: string[]) => void
  title?: string
  whereUsed?: string[]
  whereUsedList?: string[]
  header?: string
  isCategory?: boolean
}

const AttributeTitleModal = ({
  onSave,
  title,
  whereUsed = [],
  whereUsedList = [],
  header,
  isCategory = false
}: AttributeTitleModalProps) => {
  const [showIn, setShowIn] = useState<string[]>(whereUsed)

  const onFormSubmit = useCallback(
    async (values: AttributesValues) => {
      onSave(values.title, showIn)
    },
    [onSave, showIn]
  )

  useEffect(() => {
    if (!title) {
      setShowIn(whereUsedList.map((item) => item.toLowerCase()))
    }
  }, [title, whereUsedList])

  return (
    <ModalFormContainer defaultWidth={600}>
      <Formik
        onSubmit={onFormSubmit}
        initialValues={{
          ...attributesInitialValues,
          [AttributeFields.Title]: title || ""
        }}
        validationSchema={attributesValidationSchema}
      >
        {() => {
          return (
            <Form>
              <FormActionButtons
                title={header ? header : "Attribute"}
                ModalName={ModalNames.AttributeTitle}
                buttonName={messages.generic.done}
              />

              <Div mt="s" width="100%">
                <FormikInput name={AttributeFields.Title} label="Add title" />
              </Div>
              {isCategory && (
                <>
                  <H6 color="grey.dark">Show in:</H6>
                  <Div bg="white.standard" flex={1} p="5px">
                    <ShowInBody
                      whereUsed={showIn}
                      whereUsedList={whereUsedList || []}
                      changeShowIn={(showInNew: string[]) =>
                        setShowIn(showInNew)
                      }
                    />
                  </Div>
                </>
              )}
            </Form>
          )
        }}
      </Formik>
    </ModalFormContainer>
  )
}

export default AttributeTitleModal
