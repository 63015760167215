import {
  AssignmentsIcon,
  CampaignWhiteIcon,
  colors,
  CompaniesIcon,
  PeopleIcon
} from "@ikiru/talentis-fpc"
import React from "react"
import { NavigateFunction } from "react-router-dom"
import { RouterUrl } from "setup/router/routes"
import { LinkNotePreviewType } from "views/assignments/components/assignment-notes/components/NotesList"
import { NoteType } from "views/assignments/components/assignment-notes/components/NotesList/NoteAssignmentItem"

export const generateLinks = (
  note: NoteType,
  navigate: NavigateFunction,
  disableRoute?: boolean
) => {
  const linksInfoList: LinkNotePreviewType[] = []
  if (note.assignmentId) {
    linksInfoList.push({
      id: note.assignmentId,
      label: note.assignment?.name || "",
      Icon: <AssignmentsIcon width={12} height={12} viewBox="0 0 20 20" />,
      onClick: () => {
        if (!disableRoute) {
          navigate(`${RouterUrl.AssignmentDetails}/${note.assignment?.id}`)
        }
      }
    })
  }
  if (note.campaignId) {
    linksInfoList.push({
      id: note.campaignId,
      label: note.campaign?.name || "",
      Icon: (
        <CampaignWhiteIcon
          fill={colors.grey.dark}
          width={12}
          height={12}
          viewBox="0 0 20 20"
        />
      ),
      onClick: () => {
        if (!disableRoute) {
          navigate(`${RouterUrl.CampaignDetails}/${note.campaign?.id}`)
        }
      }
    })
  }
  if (note.company) {
    linksInfoList.push({
      id: note.companyId || "",
      label: note.company?.name || "",
      Icon: <CompaniesIcon width={12} height={12} viewBox="0 0 20 20" />,
      onClick: () => {
        if (!disableRoute) {
          navigate(`${RouterUrl.CompanyDetails}/${note.company?.id}`)
        }
      }
    })
  }
  if (note.person) {
    linksInfoList.push({
      id: note.personId || "",
      label: note.person?.name || "",
      Icon: <PeopleIcon width={12} height={12} viewBox="0 0 20 20" />,
      onClick: () => {
        if (!disableRoute) {
          navigate(`${RouterUrl.ProfileDetails}/${note.person?.id}`)
        }
      }
    })
  }
  return linksInfoList
}
