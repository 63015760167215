import React, { useCallback } from "react"
import { getOnAddNoteSubmit } from "components/Notes/helpers"
import NotesEditMode from "components/Notes/components/EditMode"
import { AssignmentsEndpoints } from "setup/api/endpoints/endpoints"
import { ModalNames } from "setup/modal/modal.definitions"
import { useModal } from "utils/hooks/use-modal"
import { AssignmentNote } from "../../types"

type AssignmentEditModeProps = {
  assignmentId: string
  assignmentName: string
  notes: AssignmentNote[]
  setNotes: any
  close: (modalName: ModalNames) => void
}

const AssignmentNotesEditMode = ({
  assignmentId,
  notes,
  setNotes,
  assignmentName
}: AssignmentEditModeProps) => {
  const { close } = useModal()

  const onSubmit = useCallback(
    () =>
      getOnAddNoteSubmit(AssignmentsEndpoints.Notes, assignmentId, (item) => {
        const newNote = {
          ...item.note,
          assignment: { id: assignmentId, name: assignmentName },
          linkCreatedByUser: item.createdBy,
          linkUpdatedByUser: item.updatedBy
        }
        const newNotes = [newNote, ...notes]
        setNotes(newNotes)
        close(ModalNames.EditNoteData)
      }),
    [assignmentId, assignmentName, notes, setNotes, close]
  )

  return (
    <NotesEditMode
      onSubmit={onSubmit}
      close={() => close(ModalNames.EditNoteData)}
    />
  )
}
export default React.memo(AssignmentNotesEditMode)
