import { LocalPersonModel } from "models/LocalPerson/LocalPerson"
import { StageItem } from "./assignment-module.types"
import { Candidate } from "views/candidates/candidates.types"

export const updateStage = (
  oldStage: string,
  newStage: string,
  stageArray: StageItem[]
) => {
  let updatedStage = [...stageArray]

  const oldStageIndex = updatedStage.findIndex((item) => item.data === oldStage)

  if (oldStageIndex !== -1) {
    updatedStage[oldStageIndex].count -= 1
    if (updatedStage[oldStageIndex].count === 0) {
      updatedStage.splice(oldStageIndex, 1)
    }
  }

  const newStageIndex = updatedStage.findIndex((item) => item.data === newStage)

  if (newStageIndex !== -1) {
    updatedStage[newStageIndex].count += 1
  } else {
    updatedStage.push({ data: newStage, count: 1 })
  }

  return updatedStage.filter((item) => item.count > 0)
}

export const normalizedCandidate = (candidates: Candidate[]) => {
  const newData = candidates.map((candidate: Candidate) => {
    const interviewProgressState = {
      stage: candidate.stage,
      status: candidate.status?.name,
      statusId: candidate.status?.id
    }
    return {
      ...candidate,
      interviewProgressState,
      normalizedPersonData: new LocalPersonModel(
        candidate?.linkPerson
      ).getPersonValues()
    }
  }) as unknown as Candidate[]
  return newData
}
