import React, { useCallback, useState } from "react"
import Tree from "rc-tree"
import "rc-tree/assets/index.css"
import {
  Button,
  colors,
  Div,
  Flex,
  H5,
  ModalSectionHeader
} from "@ikiru/talentis-fpc"
import { DropdownArrowIcon } from "@ikiru/talentis-fpc"
import {
  SwitcherIconStyled,
  ReassignAttributesModalWrapper,
  GlobalStyles,
  StyledBodyWrapper
} from "../../styles"
import { messages } from "setup/messages/messages"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { findIdsByKeys, getAllKeys, getCheckedLeafNodeIds } from "../../helper"
import { assignAttributes } from "../../actions"
import { Attribute } from "views/attributes/attributes-module/attributes-module.types"

const ReassignAttributes = () => {
  const [checkedTreeKeys, setCheckedTreeKeys] = useState<
    Record<string, string[]>
  >({})
  const {
    attributes,
    updateAttributes,
    personId,
    changedNodes,
    setChangedNodes
  } = usePerson()

  const { close } = useModal()

  const saveChanges = useCallback(async () => {
    const ids = getCheckedLeafNodeIds(attributes)
    const matchingIds = findIdsByKeys(checkedTreeKeys, changedNodes)

    const updatedAttributes = await assignAttributes(personId, [
      ...ids,
      ...matchingIds
    ])

    setChangedNodes([])
    updateAttributes(updatedAttributes)
    close(ModalNames.ReassignAttributes)
  }, [
    attributes,
    personId,
    updateAttributes,
    checkedTreeKeys,
    close,
    changedNodes
  ])

  const onCheck = useCallback(
    (attributeId: string) => (checkedKeys) => {
      const keys = Array.isArray(checkedKeys)
        ? checkedKeys
        : checkedKeys.checked

      setCheckedTreeKeys((prev) => ({
        ...prev,
        [attributeId]: keys
      }))
    },
    []
  )

  return (
    <ReassignAttributesModalWrapper>
      <ModalSectionHeader
        title="Assign  Attributes"
        size="xSmall"
        actions={
          <Button size="small" mr="xxs" onClick={() => saveChanges()}>
            {messages.form.generic.save}
          </Button>
        }
      />
      <Div pt="15px" pl="10px">
        {messages.attributes.adminChanges}
      </Div>

      <StyledBodyWrapper flexDirection="column">
        {changedNodes?.map?.((attribute: Attribute) => (
          <Flex key={attribute.id} flexDirection="column">
            <Flex
              mt="10px"
              mr="10px"
              ml="10px"
              backgroundColor={colors.green.dark}
            >
              <H5 m="0" py="5px" pl="10px" color={colors.white.standard}>
                {attribute.title}
              </H5>
            </Flex>
            <Div ml="10px" mt="10px">
              <GlobalStyles />
              <Tree
                treeData={attribute.children || []}
                defaultExpandedKeys={getAllKeys(changedNodes)}
                checkable
                checkedKeys={checkedTreeKeys[attribute.id!] || []}
                onCheck={onCheck(attribute.id!)}
                switcherIcon={(nodeProps) => {
                  if (nodeProps?.isLeaf) {
                    return null
                  }
                  return (
                    <SwitcherIconStyled>
                      <DropdownArrowIcon width={8} height={8} />
                    </SwitcherIconStyled>
                  )
                }}
                showIcon={false}
              />
            </Div>
          </Flex>
        ))}
      </StyledBodyWrapper>
    </ReassignAttributesModalWrapper>
  )
}

export default ReassignAttributes
