import * as Yup from "yup"
import { messages } from "setup/messages/messages"

export enum AttributeFields {
  Title = "title"
}

export type AttributesValues = {
  [AttributeFields.Title]: string
}

export const attributesInitialValues = {
  [AttributeFields.Title]: ""
}

export const attributesValidationSchema = Yup.object({
  [AttributeFields.Title]: Yup.string()
    .max(40, messages.form.generic.errors.mustBeNoLongerThan.format(40))
    .min(1, messages.form.generic.errors.mustBeNoSmallerThan.format(1))
    .required(messages.generic.required)
})
