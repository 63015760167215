import { CrossSmallIcon, Div, Flex } from "@ikiru/talentis-fpc"
import React from "react"
import { LinkNotePreviewType } from "views/assignments/components/assignment-notes/components/NotesList"
import { LinkWrapper, LinkStyled } from "./style"
import { NoteEndpoint } from "setup/api/endpoints/endpoints"
import { apiRequest } from "setup/api/api"
import { generateLinks } from "../utils"
import { AssignmentNote } from "views/assignments/components/assignment-notes/types"
import { useNavigate } from "react-router-dom"

type PreviewLinksProps = {
  noteId: string
  links: LinkNotePreviewType[]
  setNote: any
  projectId: string
  note: AssignmentNote
  companyDetails:
    | {
        id: string
        name: string
      }
    | undefined
  setLinks: React.Dispatch<React.SetStateAction<LinkNotePreviewType[]>>
}

const PreviewLinks = ({
  noteId,
  links,
  note,
  setNote,
  projectId,
  setLinks,
  companyDetails
}: PreviewLinksProps) => {
  const navigate = useNavigate()
  const onDelete = async (deleteId: string) => {
    const initialValues = {
      assignmentId: note?.assignmentId || null,
      campaignId: note?.campaignId || null,
      personId: note?.personId || null,
      companyId: note?.companyId || null
    }

    const formattedValues: any = {}

    Object.entries(initialValues).forEach(([key, value]) => {
      if (value === deleteId) {
        formattedValues[key] = null
      } else {
        formattedValues[key] = value
      }
    })

    const [error, response] = await apiRequest.put({
      endpoint: NoteEndpoint.UpdateNote,
      endpointParams: noteId,
      data: formattedValues
    })

    if (!error) {
      const newNoteData = response?.data
      //Workaround delete after 131610
      if (formattedValues.companyId) {
        if (companyDetails?.id === formattedValues.companyId) {
          newNoteData.company = companyDetails
        } else if (note.company?.id && note.company.name) {
          newNoteData.company = note.company
        }
      }

      const updatedLinks = generateLinks(newNoteData, navigate, true)
      setLinks(updatedLinks)
      setNote(newNoteData)
    }
  }

  return (
    <Flex mt="xs" flexWrap="wrap">
      {links.map((link) => (
        <LinkWrapper key={link.id}>
          <Div>{link.Icon}</Div>
          <LinkStyled size="small" style={{ cursor: "default" }}>
            {link.label}
          </LinkStyled>
          {projectId !== link.id && (
            <Div
              ml="xxs"
              style={{ cursor: "pointer" }}
              onClick={() => onDelete(link.id as string)}
            >
              <CrossSmallIcon />
            </Div>
          )}
        </LinkWrapper>
      ))}
    </Flex>
  )
}

export default PreviewLinks
