import { useEffect, useCallback, useState } from "react"
import { apiRequest } from "setup/api/api"
import { NoteEndpoint } from "setup/api/endpoints/endpoints"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"

const useAssignmentNotes = () => {
  const [isLoading, setLoading] = useState(false)
  const {
    assignmentId,
    setNotes,
    updateAssignmentNotesInfo,
    setNotesPagination
  } = useAssignment()

  const getNotes = useCallback(
    async (page: number) => {
      updateAssignmentNotesInfo({ isLoading: true })

      const [, response] = await apiRequest.get({
        endpoint: NoteEndpoint.Root,
        config: {
          params: { assignmentId, page, pageSize: 25 }
        }
      })

      if (response && response.data) {
        const { notes: notesList, ...pagination } = response.data
        const notes = notesList.map((item: any) => ({
          ...item,
          linkCreatedByUser: item.createdByUser,
          linkUpdatedByUser: item.updatedByUser
        }))
        setNotesPagination({
          ...pagination
        })
        setNotes(notes)
      } else {
        setNotes([])
      }

      updateAssignmentNotesInfo({ isLoading: false })
    },
    [assignmentId, setNotes, setNotesPagination, updateAssignmentNotesInfo]
  )

  const updateNotes = useCallback(async () => {
    if (!assignmentId) return
    setLoading(true)
    await getNotes(1)
    setNotesPagination({
      onPageChange: getNotes
    })
    setLoading(false)
  }, [assignmentId, getNotes, setNotesPagination])

  useEffect(() => {
    updateNotes()
  }, [updateNotes])

  return { isLoading }
}

export { useAssignmentNotes }
