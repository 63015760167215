import React from "react"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { messages } from "setup/messages/messages"
import { useModal } from "utils/hooks/use-modal"
import { ModalNames } from "setup/modal/modal.definitions"
import { Keyword } from "views/persons/person-module/person-module.types"
import { colors, Div, Flex, H5, SmallText, Tag } from "@ikiru/talentis-fpc"
import { StyledRemoveButton } from "views/internal-search/InternalSearchModule/style"
import { StyledPlusIcon } from "../../styles"
import { List } from "views/search/components/KeywordsList/style"
import { updateKeyword } from "views/persons/components/person-keyword/form/actions"
import PersonKeywordEdit from "views/persons/components/person-keyword/form/PersonKeywordEdit"

export const PersonKeywords = () => {
  const { keywords, personId, setKeywords } = usePerson()
  const { open: openModal, close } = useModal()

  const onSubmit = async (newKeywords: Keyword[]) => {
    const resp = await updateKeyword(personId, newKeywords)

    setKeywords(resp?.keywords)
    close(ModalNames.EditKeywords)
  }

  const addKeyword = () => {
    openModal(
      ModalNames.EditKeywords,
      <PersonKeywordEdit onSubmit={onSubmit} currentKeywords={keywords} />
    )
  }

  return (
    <Div mb="20px">
      <Flex alignItems="center" mb="10px">
        <H5 my="0" mr="5px">
          {messages.person.personTabSection.form.attributesEditMode}
        </H5>
        <StyledRemoveButton
          mode="standard-white"
          size="action-small"
          onClick={addKeyword}
        >
          <StyledPlusIcon />
        </StyledRemoveButton>
      </Flex>
      {Boolean(keywords.length) ? (
        <List>
          {keywords.map((keyword, index) => (
            <Tag
              key={index + keyword.value}
              as="li"
              mr="xxs"
              variant="grey"
              disabled
            >
              {keyword.value}
            </Tag>
          ))}
        </List>
      ) : (
        <SmallText m="0" pb="20px" color={colors.grey.standard}>
          {messages.person.personTabSection.noDataTexts.noAttributes}
        </SmallText>
      )}
    </Div>
  )
}

export default PersonKeywords
