import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Candidate } from "views/candidates/candidates.types"
import { Flex } from "@ikiru/talentis-fpc"
import FormikSelect from "components/functional/formik/formik-select/FormikSelect"
import { Formik, Form, FormikHelpers } from "formik"
import {
  interviewProgressInitialValues,
  InterviewProgressField,
  InterviewProgressValues,
  interviewProgressE2ETargets,
  debounceDuration,
  interviewProgressInitialTouched
} from "views/persons/components/person-assignments/components/InterviewProgress/constants/interview-progress.definitions"
import { usePerson } from "views/persons/person-module/candiate-module.context"
import { AutoSave } from "components/functional/formik/formik-autosave/AutoSave"
import { getOnChangeInterviewProgress } from "views/persons/components/person-assignments/components/InterviewProgress/interview-progress.actions"
import { FormikCheckedDatapicker } from "components/functional/formik/formik-checked-datapicker/FormikCheckedDatepicker"
import { useTeam } from "views/team/team/team-module.context"
import { messages } from "setup/messages/messages"
import { useAssignment } from "views/assignments/assignment-module/assignment-module.context"
import { useTaskManagement } from "views/homepage/task-management-module/task-management-module.context"
import { useLocation } from "react-router-dom"
import {
  candidateStages,
  DefaultStatusValue
} from "views/assignments/components/candidates-list/components/candidate-record/components/InterviewProgress/definitions"
import { InterviewProgressStage } from "components/ProjectPersonRecord/assignment.definitions"
import { apiRequest } from "setup/api/api"
import { CandidateStatusesEndpoint } from "setup/api/endpoints/endpoints"
import { getChangedValues } from "views/assignments/utils"

type InterviewProgressProps = {
  candidate: Candidate
}

export const InterviewProgress = (props: InterviewProgressProps) => {
  const { setCandidate, setPersonOverlayUpdated } = usePerson()
  const [statusList, setStatusList] = useState<
    { value: string; label: string }[]
  >([
    {
      label: messages.form.generic.noStatus,
      value: ""
    }
  ])
  const { teamMembers } = useTeam()
  const {
    setSelectedStage,
    activeStages,
    selectedStage,
    assignmentId,
    updateCandidateStage,
    filterTotalNumber,
    totalItems,
    totalPages,
    setRecordsCount,
    updateCompaniesStage
  } = useAssignment()
  const { isAssignment } = useTaskManagement()
  const location = useLocation()
  const { candidate } = props

  const membersOptions = useMemo(
    () =>
      teamMembers?.map((member) => ({
        label: `${member.firstName} ${member.lastName}`,
        value: member.id
      })),
    [teamMembers]
  )

  const initialValues = useMemo(() => {
    return {
      ...interviewProgressInitialValues,
      ...{
        stage: candidate.stage || null,
        status: candidate.status?.id || "noStatus"
      },
      assignTo: candidate.assignTo,
      dueDate: candidate.dueDate
    } as InterviewProgressValues
  }, [
    candidate.stage,
    candidate.status?.id,
    candidate.assignTo,
    candidate.dueDate
  ])

  const onSubmit = getOnChangeInterviewProgress(candidate.id, (data) => {
    if (candidate.stage !== data.stage) {
      updateCompaniesStage(candidate.stage || "", data)

      updateCandidateStage(candidate.id, data)

      if (activeStages[`${selectedStage}`] === 1) {
        setSelectedStage(data.stage)
      }
    }

    const isHomeScreen = location.pathname === "/"
    if (
      assignmentId === candidate.assignmentId ||
      (isAssignment && isHomeScreen)
    ) {
      if (
        data.interviewProgressState.stage === InterviewProgressStage.Archive
      ) {
        setRecordsCount(totalItems, totalPages, filterTotalNumber - 1)
      }
      setPersonOverlayUpdated(true)
    }
    setCandidate(data)
  })

  const submitForm = (values: any, actions: FormikHelpers<any>) => {
    const formattedInitialValues = {
      assignTo: initialValues.assignTo,
      dueDate: initialValues.dueDate,
      stage: initialValues.stage,
      statusId: initialValues.status
    }

    const formattedValues = {
      assignTo: values.assignTo,
      dueDate: values.dueDate,
      stage: values.stage,
      statusId: values.status
    }

    const changeValues = getChangedValues(
      formattedValues,
      formattedInitialValues
    )

    onSubmit(changeValues, actions)
  }

  const getStatusList = useCallback(async () => {
    const [, response] = await apiRequest.get({
      endpoint: CandidateStatusesEndpoint.Root,
      config: {
        params: {
          parentStage: candidate.stage
        }
      }
    })

    const statusSelectList = response?.data.map(
      (statusInfo: { id: string; name: string }) => {
        return { value: statusInfo.id, label: statusInfo.name }
      }
    )

    statusSelectList.unshift(DefaultStatusValue)
    setStatusList(statusSelectList)
  }, [candidate.stage])

  useEffect(() => {
    getStatusList()
  }, [getStatusList])

  return (
    <Formik
      initialValues={initialValues}
      initialTouched={interviewProgressInitialTouched}
      onSubmit={submitForm}
      enableReinitialize={true}
    >
      {({ handleChange, setFieldValue }) => (
        <AutoSave debounceMs={debounceDuration}>
          <Form>
            <Flex alignItems="start" justifyContent={["flex-start", "center"]}>
              <Flex width="100%" flexDirection="column" mr="xs">
                <FormikSelect
                  layout={{
                    mb: ["xxs"],
                    position: "relative",
                    zIndex: 2
                  }}
                  variant="small"
                  options={candidateStages}
                  id={InterviewProgressField.Stage}
                  name={InterviewProgressField.Stage}
                  data-e2e-target-name={
                    interviewProgressE2ETargets[InterviewProgressField.Stage]
                  }
                  onChange={(e) => {
                    setFieldValue("status", "noStatus")
                    handleChange(e)
                  }}
                />
                <FormikSelect
                  layout={{
                    mt: ["none", "xxs"],
                    position: "relative",
                    zIndex: 2
                  }}
                  variant="small"
                  options={statusList}
                  id={InterviewProgressField.Status}
                  name={InterviewProgressField.Status}
                  data-e2e-target-name={
                    interviewProgressE2ETargets[InterviewProgressField.Status]
                  }
                />
              </Flex>
              <Flex width="100%" flexDirection="column">
                <FormikCheckedDatapicker
                  variant="small"
                  layout={{
                    mt: ["none"],
                    mb: ["xxs"],
                    position: "static",
                    zIndex: 3
                  }}
                  id={InterviewProgressField.DueDate}
                  name={InterviewProgressField.DueDate}
                  label={messages.person.assignments.dueDate}
                  data-e2e-target-name={`candidate-${
                    candidate.normalizedPersonData?.name
                  }-${
                    interviewProgressE2ETargets[InterviewProgressField.DueDate]
                  }`}
                />
                <FormikSelect
                  layout={{
                    mt: ["none", "xxs"],
                    position: "relative",
                    zIndex: 2
                  }}
                  variant="small"
                  firstOption={{
                    label: messages.form.generic.assignTo,
                    value: ""
                  }}
                  options={membersOptions}
                  id={InterviewProgressField.AssignTo}
                  name={InterviewProgressField.AssignTo}
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                  }}
                  width="80%"
                  data-e2e-target-name={`candidate-${
                    candidate.normalizedPersonData?.name
                  }-${
                    interviewProgressE2ETargets[InterviewProgressField.AssignTo]
                  }`}
                />
              </Flex>
            </Flex>
          </Form>
        </AutoSave>
      )}
    </Formik>
  )
}
