import { CampaignNote } from "../components/campaign-notes/types"
import {
  CampaignState,
  CampaignActionTypes,
  CampaignContextValues
} from "./campaign-module.types"

export const initialCampaignState: CampaignState = {
  isLoading: true,
  campaignDetails: null!,
  notes: [],
  contacts: [],
  isContactsLoading: true,
  contactPhotos: {},
  selectedContactId: "",
  paginationContact: {
    totalItemCount: 0,
    totalFilteredCount: 0,
    pageNumber: 1,
    pageSize: 25
  },
  appliedFilters: [],
  allAvailableFilters: {
    status: [],
    dueDates: null,
    assignedTo: null,
    tags: null
  },
  campaignAITemplates: {
    connectionRequest: "",
    consentNotification: "",
    coreMessage: "",
    introductoryEmail: "",
    liNotification: "",
    linkedinMessage: ""
  },
  notesPagination: {
    firstItemOnPage: 1,
    totalItemCount: 1,
    pageSize: 1,
    hasNextPage: false,
    hasPreviousPage: false,
    pageNumber: 1,
    maxPages: 5,
    pageCount: 1,
    onPageChange: () => {}
  }
}

export const campaignReducer = (
  state: CampaignState,
  action: { type: string; payload?: any }
) => {
  const { type, payload } = action
  switch (type) {
    case CampaignActionTypes.SetCampaignPaginationContact:
      return {
        ...state,
        paginationContact: { ...state.paginationContact, ...payload }
      }
    case CampaignActionTypes.SetIsLoading:
      return { ...state, isLoading: payload }
    case CampaignActionTypes.SetCampaignDetails:
      return { ...state, campaignDetails: payload }
    case CampaignActionTypes.SetSelectedContactId: {
      return { ...state, selectedContactId: payload }
    }
    case CampaignActionTypes.SetNotes:
      const sortedData = payload.sort(
        (a: CampaignNote, b: CampaignNote) =>
          new Date(b.updatedDate ? b.updatedDate : b.createdDate).getTime() -
          new Date(a.updatedDate ? a.updatedDate : a.createdDate).getTime()
      )
      return {
        ...state,
        notes: sortedData || []
      }
    case CampaignActionTypes.SetContacts:
      return {
        ...state,
        contacts: payload
      }
    case CampaignActionTypes.SetIsContactsLoading:
      return { ...state, isContactsLoading: payload }
    case CampaignActionTypes.UpdatePhoto: {
      return {
        ...state,
        contactPhotos: {
          ...state.contactPhotos,
          [payload.contactId]: payload.photo
        }
      }
    }
    case CampaignActionTypes.ApplyFilters:
      return {
        ...state,
        appliedFilters: payload.contactFilters
      }
    case CampaignActionTypes.SetAllAvailableFilters: {
      return { ...state, allAvailableFilters: payload }
    }
    case CampaignActionTypes.SetCampaignAITemplates: {
      return {
        ...state,
        campaignAITemplates: { ...state.campaignAITemplates, ...payload }
      }
    }
    case CampaignActionTypes.SetNotesPagination: {
      return {
        ...state,
        notesPagination: { ...state.notesPagination, ...payload }
      }
    }
    default:
      return state
  }
}

export const initialCampaignContextValues: CampaignContextValues = {
  ...initialCampaignState,
  campaignId: "",
  setCampaignDetails: () => {},
  setCampaignPaginationContact: () => {},
  getCampaignData: () => {},
  setNotes: () => {},
  setContacts: () => {},
  getCampaignContacts: () => {},
  updateContactStage: () => {},
  setSelectedContactId: () => {},
  updateFilters: () => {},
  setIsContactsLoading: () => {},
  setCampaignAITemplates: () => {},
  setNotesPagination: () => {}
}
