import React, { useCallback, useEffect, useReducer } from "react"
import {
  SetIsLoading,
  SetAttributes,
  AttributesActionTypes,
  SetWhereUsedList,
  Attribute
} from "./attributes-module.types"
import { AttributesContext } from "./attributes-module.context"
import {
  attributesReducer,
  initialAttributesState
} from "./attributes-module.reducer"
import { getAllAttributes, getWhereUsedList } from "./actions"

type AttributesModuleProps = {
  children: React.ReactNode
}

export const AttributesModule = ({ children }: AttributesModuleProps) => {
  const [state, dispatch] = useReducer(
    attributesReducer,
    initialAttributesState
  )

  const setIsLoading: SetIsLoading = useCallback(
    (isLoading: boolean) => {
      dispatch({ type: AttributesActionTypes.SetIsLoading, payload: isLoading })
    },
    [dispatch]
  )

  const setAttributes: SetAttributes = useCallback(
    (attributes: Attribute[]) => {
      dispatch({
        type: AttributesActionTypes.SetAttributes,
        payload: attributes
      })
    },
    [dispatch]
  )

  const setWhereUsedList: SetWhereUsedList = useCallback(
    (whereUsedList: string[]) => {
      dispatch({
        type: AttributesActionTypes.SetWhereUsedList,
        payload: whereUsedList
      })
    },
    [dispatch]
  )

  const fetchAttributes = useCallback(async () => {
    setIsLoading(true)

    const allAttributes = await getAllAttributes()

    const whereUsedList = await getWhereUsedList()

    setWhereUsedList(whereUsedList)
    setAttributes(allAttributes)

    setIsLoading(false)
  }, [setIsLoading, setAttributes])

  useEffect(() => {
    fetchAttributes()
  }, [fetchAttributes])

  return (
    <AttributesContext.Provider
      value={{
        ...state,
        setAttributes,
        setIsLoading,
        setWhereUsedList
      }}
    >
      {children}
    </AttributesContext.Provider>
  )
}
