import React, { useCallback } from "react"
import { messages } from "setup/messages/messages"
import CampaignNotesEditMode from "./components/EditMode"
import NotesList from "./components/NotesList"
import { useCampaign } from "views/campaigns/campaign-module/campaign-module.context"
import { Button, Div, Section } from "@ikiru/talentis-fpc"
import { ModalNames } from "setup/modal/modal.definitions"
import { useModal } from "utils/hooks/use-modal"
import ActionButton from "components/ProjectActions/ActionButton"

type CampaignNotesProps = {
  isLoading: boolean
}

const CampaignNotes = ({ isLoading }: CampaignNotesProps) => {
  const { campaignId, notes, setNotes, campaignDetails } = useCampaign()

  const { open, close } = useModal()

  const noteOpenModal = useCallback(() => {
    open(
      ModalNames.EditNoteData,
      <CampaignNotesEditMode
        {...{
          campaignId,
          notes,
          setNotes,
          close,
          campaignName: campaignDetails.name
        }}
      />
    )
  }, [campaignId, close, notes, open, setNotes])

  return (
    <Section
      size="xxSmall"
      header={{
        title: messages.person.note.title,
        actions: (
          <ActionButton
            mode="standard-green"
            size="small"
            onClick={noteOpenModal}
            label={messages.person.note.plusNote}
          />
        )
      }}
    >
      <Div width="100%" py="s">
        <NotesList isLoading={isLoading} />
      </Div>
    </Section>
  )
}
export default React.memo(CampaignNotes)
